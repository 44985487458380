import classNames from 'classnames'
import * as React from 'react'
import styles from './Grid.module.css'

export interface GridProps {
  columns: 2 | 8 | 12
  columnGap?: 8 | 12 | 16 | 24
  rowGap?: 12 | 24 | 40
  alignCenter?: boolean
  className?: string
}

export const Grid = React.forwardRef<
  HTMLDivElement,
  React.PropsWithChildren<GridProps>
>((props, ref?) => (
  <div
    className={classNames(
      styles['container'],
      styles[`cols-${props.columns}`],
      styles[`column-gap-${props.columnGap ?? 24}`],
      styles[`row-gap-${props.rowGap ?? 24}`],
      props.alignCenter && styles['align-center'],
      props.className
    )}
    ref={ref}
  >
    {props.children}
  </div>
))
