import classNames from 'classnames'
import { useRouter } from 'next/router'
import * as React from 'react'
import { AirlineLogo } from '@components/AirlineLogo'
import { Icon } from '@components/Icon'
import { Text } from '@components/Text'
import { VSpace } from '@components/VSpace'
import { getDateString } from '@lib/date'
import { useKeyPress } from '@lib/hooks'
import { ordersShowPathArray } from '@lib/paths'
import { trackEvent } from '@lib/tracking'
import { JwtPayload } from '@lib/types'
import { useWorkspace } from '@lib/workspace-context'
import { getOrderStatus, OrderStatusStamp } from '@modules/air-order'
import styles from './OrderSearchResult.module.css'
import { type OrderSearchResult as OrderSearchResultType } from 'pages/api/search'

export type OrderSearchResultProps = {
  /** Order item returned from Meilisearch */
  order: OrderSearchResultType

  /** Should the order item have the active/hover styles? */
  active?: boolean

  /** ID to attach to DOM element */
  id: string

  /** onMouseEnter event used to override active order item set on SearchModalResultsBody */
  onMouseEnter: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void
}

export const OrderSearchResult: React.FC<OrderSearchResultProps> = ({
  order,
  active = false,
  ...buttonElementProps
}) => {
  const router = useRouter()
  const { permissions, user } = useWorkspace()
  const { organisation, organisationId, liveMode }: Partial<JwtPayload> =
    permissions || {}
  const { id: userId } = user || {}

  const resultsButtonClassNames = classNames(styles['result-item'], {
    [styles['result-item--active']]: active,
  })

  const handleSearchResultSelect = () => {
    trackEvent('dashboard_order_search_result_clicked', {
      event_type: 'interaction',
      mode: liveMode ? 'live' : 'test',
      ...(userId && { userId }),
      ...(organisation && { organisation }),
      ...(organisationId && { organisationId }),
    })

    router.push(
      ...ordersShowPathArray(
        permissions?.organisation,
        permissions?.liveMode,
        order.id
      )
    )
  }

  useKeyPress('Enter', (event) => {
    if (!active) return

    event.preventDefault()
    handleSearchResultSelect()
  })

  return (
    <button
      {...buttonElementProps}
      className={resultsButtonClassNames}
      onClick={handleSearchResultSelect}
    >
      {order.owner && (
        <AirlineLogo
          name={order.owner.name}
          iataCode={order.owner.iataCode}
          size={24}
        />
      )}
      <VSpace space={4}>
        <div className={styles['trip-status']}>
          <Text asElement="h2" fontSize="C1" fontWeight="medium">
            {order.bookingReference}
          </Text>
          {/* `OrderSearchResult` has all the properties needed for
          getOrderStatus to work. Typescript cannot infer that since the function expects that the order passed to this function is complete.
          We can safely cast `order` as `any` to avoid the typescript error. */}
          <OrderStatusStamp status={getOrderStatus(order as any)} />
        </div>
        <div className={styles['trip-details']}>
          <Text fontSize="C2" className={styles['origin-destination']}>
            {order.slices[0].origin.iataCode}
            {order.slices.length === 2 ? (
              <Icon name="sync" size={12} className={styles['trip-icon']} />
            ) : (
              <Icon
                name="arrow_right"
                size={12}
                className={styles['trip-icon']}
              />
            )}
            {order.slices[0].destination.iataCode}
          </Text>
          {order.slices.length > 2 && (
            <Text
              fontSize="C2"
              className={`${styles['text']} ${styles['trip-additional-slices']}`}
            >
              +{order.slices.length - 2} more
            </Text>
          )}
          <span className={styles['delimiter']}>|</span>
          <Text fontSize="C2">
            {getDateString(
              order.slices[0].segments[0].departureDatetime,
              'mediumWithTime'
            )}
          </Text>
          <span className={styles['delimiter']}>|</span>
          <Text fontSize="C2">
            {new Intl.NumberFormat('en-GB', {
              style: 'currency',
              currency: order.totalCurrency,
              currencyDisplay: 'narrowSymbol',
            }).format(Number.parseInt(order.totalAmount))}
          </Text>
        </div>
        <Text
          fontSize="C2"
          className={`${styles['text']} ${styles['trip-passengers']}`}
        >
          {order.passengers
            .map(
              (passenger) => `${passenger.givenName} ${passenger.familyName}`
            )
            .join(', ')}
        </Text>
      </VSpace>
    </button>
  )
}
