import * as React from 'react'
import { Text } from '@components/legacy-design-system/product/components/Text'
import Link from 'next/link'
import { Avatar } from '@components/Avatar'
import { Icon } from '@components/Icon'
import { Menu, MenuItem } from '@components/Menu'
import { Portal } from '@components/Portal'
import { Divider } from '@components/Divider/Divider'
import { HSpace } from '@components/HSpace'
import { Stamp } from '@components/Stamp'
import { getAvatarLink } from '@lib/duffel-client'
import { useDuffelPopper } from '@lib/hooks'
import { organisationPreferencesIndexPathObject } from '@lib/paths'
import { useWorkspace } from '@lib/workspace-context'
import { AuthorisationGuard } from '@lib/security/AuthorisationGuard'
import { useLocalStorage } from '@lib/hooks/use-local-storage'
import { signOutAndRedirect } from '@lib/security/sign-out'
import {
  deleteFirestoreUserDismissedFeedCards,
  trackEvent,
} from '@lib/tracking'
import { TeamButton } from './TeamButton'

import styles from './TeamSwitcher.module.css'

export const TeamSwitcher: React.FC = () => {
  const [isPopoverOpen, setIsPopoverOpen] = React.useState(false)
  const onClosePopover = () => setIsPopoverOpen(false)
  const { popper, setReferenceElement, setPopperElement } = useDuffelPopper(
    isPopoverOpen,
    onClosePopover,
    {
      placement: 'bottom-start',
      modifiers: [{ name: 'offset', options: { offset: [0, 8] } }],
    },
    {
      shouldInsideClickClose: true,
    }
  )
  const { styles: popperStyles, attributes } = popper

  const { permissions, user } = useWorkspace()

  const [
    showingFeatureToggleOverridesPanel,
    setShowingFeatureToggleOverridesPanel,
  ] = useLocalStorage<boolean>('show-feature-toggle-overrides-panel', false)

  if (!user || !permissions) return null

  const organisation = user.organisationsBySlug[permissions.organisation]

  if (!organisation) return null

  const { organisationsBySlug, organisationSlugs } = user

  return (
    <div className={styles['container']}>
      <button
        className={styles['team-select']}
        type="button"
        ref={setReferenceElement}
        onClick={() => {
          setIsPopoverOpen(!isPopoverOpen)
        }}
      >
        <HSpace space={12} alignCenter>
          <Avatar
            size="smaller"
            imageUrl={getAvatarLink(organisation)}
            name={organisation.name}
          />
          <HSpace space={4} alignCenter>
            <Text
              typeStyle="paragraph3"
              fontWeight="medium"
              className="u-paddingRight2"
            >
              {organisation.name}
            </Text>
            <Icon size={16} name="arrow_drop_down" color="grey-900" />
          </HSpace>
        </HSpace>
      </button>

      {isPopoverOpen && (
        <Portal>
          <div
            className={styles['team-menu']}
            ref={setPopperElement}
            style={{ zIndex: 50, ...popperStyles.popper }}
            {...attributes}
          >
            <Menu>
              {organisationSlugs.map((orgSlug) => {
                const org = organisationsBySlug[orgSlug]
                const isActive = orgSlug === permissions.organisation

                return (
                  <li key={orgSlug} data-selector="fs-show">
                    <TeamButton active={isActive} org={org} />
                  </li>
                )
              })}

              <Link href="/create-team" style={{ textDecoration: 'none' }}>
                <HSpace
                  space={8}
                  alignCenter
                  className={styles['new-team-button']}
                >
                  <HSpace
                    space={0}
                    alignCenter
                    justify="center"
                    className={styles['new-team-avatar']}
                  >
                    <Icon name="add" size={16} />
                  </HSpace>
                  <Text typeStyle="paragraph3">New team</Text>
                </HSpace>
              </Link>

              <AuthorisationGuard requiredRole="roles/user/admin">
                <Divider />

                <AuthorisationGuard requiresDuffelUser>
                  <HSpace space={4} alignCenter spaceBetween>
                    <MenuItem
                      label={`${
                        showingFeatureToggleOverridesPanel ? 'Hide' : 'Show'
                      } toggle overrides`}
                      onClick={() =>
                        setShowingFeatureToggleOverridesPanel(
                          !showingFeatureToggleOverridesPanel
                        )
                      }
                    />
                    <Stamp
                      icon="science"
                      label="Duffel user"
                      size="small"
                      border
                      color="purple"
                    />
                  </HSpace>

                  <HSpace space={4} alignCenter spaceBetween>
                    <MenuItem
                      label="Reset feed cards state"
                      onClick={() => deleteFirestoreUserDismissedFeedCards()}
                    />
                    <Stamp
                      icon="science"
                      label="Duffel user"
                      size="small"
                      border
                      color="purple"
                    />
                  </HSpace>
                </AuthorisationGuard>
                <MenuItem
                  data-selector="fs-show"
                  label="Settings"
                  {...organisationPreferencesIndexPathObject(
                    permissions.organisation
                  )}
                />
              </AuthorisationGuard>

              <Divider />
              <MenuItem
                data-selector="fs-show"
                label="Sign out"
                onClick={() => {
                  trackEvent('dashboard_signout_link_clicked', {
                    event_type: 'interaction',
                  })
                  signOutAndRedirect()
                }}
              />
            </Menu>
          </div>
        </Portal>
      )}
    </div>
  )
}
