import classNames from 'classnames'
import { HSpace } from '@components/HSpace'
import { Icon, IconName } from '@components/Icon'
import { Text } from '@components/Text'
import styles from './TopBanner.module.css'

export interface TopBannerProps {
  icon: IconName
  title: string
  intent?: 'default' | 'warning' | 'attention'
}

export const TopBanner: React.FC<React.PropsWithChildren<TopBannerProps>> = ({
  icon,
  title,
  intent = 'default',
  children,
}) => (
  <HSpace
    space={8}
    className={classNames(styles['banner'], {
      [styles['banner--warning']]: intent === 'warning',
      [styles['banner--attention']]: intent === 'attention',
    })}
  >
    <Icon
      name={icon}
      size={20}
      className={classNames(styles['icon'], {
        [styles['icon--warning']]: intent === 'warning',
        [styles['icon--attention']]: intent === 'attention',
      })}
    />
    <Text fontSize="C2" fontWeight="medium" className={styles['text']}>
      {title}
    </Text>
    <Text fontSize="C2" className={styles['text']} asElement="div">
      {children}
    </Text>
  </HSpace>
)
