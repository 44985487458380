import classNames from 'classnames'
import * as React from 'react'
import { Stamp, StampProps } from '@components/Stamp'
import styles from './SearchFacet.module.css'

export type SearchFacetProps = Pick<
  StampProps,
  'className' | 'color' | 'icon'
> & {
  /** example search term */
  searchTerm: StampProps['label']

  /** description of search facet */
  children?: React.ReactNode
}

export const SearchFacet: React.FC<SearchFacetProps> = ({
  className,
  color = 'light-grey',
  icon = 'search',
  searchTerm,
  children,
}) => {
  const searchFacetClassnames = classNames(
    className,
    styles['search-facet'],
    styles['stamp']
  )
  return (
    <span className={styles['container']}>
      <Stamp
        size="small"
        className={searchFacetClassnames}
        color={color}
        icon={icon}
        label={searchTerm}
      />
      <span className={styles['description']}>{children}</span>
    </span>
  )
}
