import * as React from 'react'
import { useUnleashClient } from '@unleash/proxy-client-react'
import { Button } from '@components/Button'
import { HSpace } from '@components/HSpace'
import { Input } from '@components/Input'
import { Text } from '@components/Text'
import { PopoverContainer } from '@components/PopoverContainer'
import { VSpace } from '@components/VSpace'
import { useDuffelPopper } from '@lib/hooks'
import { AuthorisationGuard } from '@lib/security/AuthorisationGuard'
import { OverrideState, useFeatureFlagOverrides } from '@lib/unleash'

import { useLocalStorage } from '@lib/hooks/use-local-storage'
import styles from './FeatureFlagsPanel.module.css'

export const FeatureFlagsPanel: React.FC = () => {
  const [searchTerm, setSearchTerm] = React.useState('')
  const [isPanelOpen, setIsPanelOpen] = React.useState(false)
  const { popper, setReferenceElement, setPopperElement } = useDuffelPopper(
    isPanelOpen,
    () => setIsPanelOpen(false),
    {
      placement: 'bottom-start',
      modifiers: [{ name: 'offset', options: { offset: [0, 8] } }],
    },
    { shouldInsideClickClose: false }
  )
  const { featureFlagOverrides, setFeatureFlagOverride } =
    useFeatureFlagOverrides()

  const client = useUnleashClient()

  const [showFeatureToggleOverridesPanel] = useLocalStorage<boolean>(
    'show-feature-toggle-overrides-panel',
    false
  )

  if (!client || !showFeatureToggleOverridesPanel) {
    return null
  }

  const enabledToggles = new Set(
    client.getAllToggles().map((toggle) => toggle.name)
  )

  const onToggleOverride = (
    toggle: string,
    currentState: OverrideState,
    newState: OverrideState
  ) => {
    setFeatureFlagOverride(
      toggle,
      currentState === newState ? 'inherit' : newState
    )
  }

  const numOverrides = Object.values(featureFlagOverrides).filter(
    (value) => value !== 'inherit'
  ).length

  const filteredFeatureFlags = Object.entries(featureFlagOverrides).filter(
    ([toggle, _]) => searchTerm === '' || toggle.includes(searchTerm)
  )

  return (
    <AuthorisationGuard requiresDuffelUser>
      <Button
        intent="MUTED"
        text={String(numOverrides)}
        {...(numOverrides > 0
          ? { iconBefore: 'science' }
          : { iconOnly: 'science' })}
        ref={setReferenceElement}
        onClick={() => setIsPanelOpen(true)}
      />
      {isPanelOpen && (
        <PopoverContainer
          ref={setPopperElement}
          style={{
            zIndex: 100,
            ...popper.styles.popper,
            padding: 0,
          }}
          {...popper.attributes}
        >
          <button
            className={`u-padding16 ${styles['container']}`}
            onClick={(event) => event.stopPropagation()}
          >
            <Input
              placeholder="Search feature flag"
              value={searchTerm}
              onChange={(event) => setSearchTerm(event.target.value)}
              containerSize="small"
            />
            <VSpace space={16} className={styles['list-container']}>
              {filteredFeatureFlags.length === 0 &&
                `No feature toggles matching '${searchTerm}'`}
              {filteredFeatureFlags.map(([toggle, state]) => (
                <HSpace key={toggle} space={24} justify="space-between">
                  <VSpace space={0}>
                    <Text
                      className={styles['feature-flag-name']}
                      fontWeight="medium"
                      fontSize="C2"
                    >
                      {toggle}
                    </Text>
                    <Text fontSize="C3">
                      Original:{' '}
                      <b>
                        {enabledToggles.has(toggle) ? 'Enabled' : 'Disabled'}
                      </b>
                    </Text>
                  </VSpace>
                  <HSpace space={4} style={{ flex: 1 }}>
                    <Button
                      intent={state === 'overrideEnabled' ? 'PRIMARY' : 'MUTED'}
                      iconOnly="check"
                      text=""
                      outlined={state !== 'overrideEnabled'}
                      onClick={() => {
                        onToggleOverride(toggle, state, 'overrideEnabled')
                      }}
                    />
                    <Button
                      intent={
                        state === 'overrideDisabled' ? 'PRIMARY' : 'MUTED'
                      }
                      iconOnly="block"
                      text=""
                      outlined={state !== 'overrideDisabled'}
                      onClick={() => {
                        onToggleOverride(toggle, state, 'overrideDisabled')
                      }}
                    />
                  </HSpace>
                </HSpace>
              ))}
            </VSpace>
          </button>
          <div className={styles['info']}>
            <Text textAlign="center" fontSize="C2" color="grey-600">
              This will only affect your current session
            </Text>
          </div>
        </PopoverContainer>
      )}
    </AuthorisationGuard>
  )
}
