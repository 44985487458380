import * as React from 'react'
import Link from 'next/link'
import { useRouter } from 'next/router'
import { FC } from 'react'
import { ValueOf } from 'type-fest'
import { Text } from '@components/legacy-design-system/product/components/Text'
import { Anchor } from '@components/Anchor'
import { TooltipWhite } from '@components/TooltipWhite'
import { Switch } from '@components/Switch'
import { HSpace } from '@components/HSpace'
import { VSpace } from '@components/VSpace'
import {
  getActivationLink,
  orgModePathsMap,
  toggleModeOnCurrentPath,
  toggleModeOnPath,
} from '@lib/paths'
import { trackEvent } from '@lib/tracking'
import { useWorkspace } from '@lib/workspace-context'
import { captureException } from '@lib/sentry'

type OrgModePath = ValueOf<typeof orgModePathsMap>

const pathsWithModeSwitch: Array<OrgModePath> = [
  '/[org]/[mode]',
  '/[org]/[mode]/balance',
  '/[org]/[mode]/developers/tokens',
  '/[org]/[mode]/developers/webhooks',
  '/[org]/[mode]/orders',
  '/[org]/[mode]/search-v2',
  '/[org]/[mode]/search-v2/ngs/results',
  '/[org]/[mode]/stays',
  '/[org]/[mode]/stays/search',
  '/[org]/[mode]/stays/results',
  '/[org]/[mode]/stays/results-by-id',
  '/[org]/[mode]/wallet',
  '/[org]/[mode]/inbox',
]

const pathsModeSwitchMap: { [key in OrgModePath]?: OrgModePath } = {
  '/[org]/[mode]/orders/[orderId]': '/[org]/[mode]/orders',
  '/[org]/[mode]/orders/[orderId]/change': '/[org]/[mode]/orders',
  '/[org]/[mode]/orders/[orderId]/change/[orderChangeRequestId]':
    '/[org]/[mode]/orders',
  '/[org]/[mode]/orders/[orderId]/change/[orderChangeRequestId]/[orderChangeId]':
    '/[org]/[mode]/orders',
  '/[org]/[mode]/search-v2/results': '/[org]/[mode]/search-v2',
  '/[org]/[mode]/search-v2/ngs/results': '/[org]/[mode]/search-v2',
  '/[org]/[mode]/search-v2/[partialOfferRequestId]/[offerId]':
    '/[org]/[mode]/search-v2',
  '/[org]/[mode]/balance/[paymentIntentId]': '/[org]/[mode]/balance',
  '/[org]/[mode]/developers/tokens/[tokenId]':
    '/[org]/[mode]/developers/tokens',
  '/[org]/[mode]/developers/tokens/new': '/[org]/[mode]/developers/tokens/new',
  '/[org]/[mode]/stays/results': '/[org]/[mode]/stays/search',
  '/[org]/[mode]/stays/results-by-id': '/[org]/[mode]/stays/search',
  '/[org]/[mode]/stays/[searchResultId]': '/[org]/[mode]/stays/search',
  '/[org]/[mode]/stays/[searchResultId]/[rateId]': '/[org]/[mode]/stays/search',
  '/[org]/[mode]/stays/bookings/[bookingId]': '/[org]/[mode]/stays',
}

export const ModeToggle: FC = () => {
  const router = useRouter()
  const {
    permissions,
    user,
    currentOrganisation,
    stripeConnectVerificationStatus,
    openBillingModal,
    openDialog,
    closeDialog,
  } = useWorkspace()

  const doesCurrentPathHaveModeSwitch =
    pathsWithModeSwitch.findIndex((path) => path === router.pathname) !== -1

  if (!permissions || !user || !router.query.mode) {
    return null
  }

  const isVerified =
    user.organisationsBySlug[permissions.organisation].isVerified
  const activationLink = getActivationLink(
    currentOrganisation,
    user?.emailConfirmedAt
  )

  // if the user is verified on stripe but still isn't considered verified on Duffel, it means that they
  // need to add the billing details
  if (!isVerified && stripeConnectVerificationStatus === 'verified') {
    return (
      <TooltipWhite
        text={
          <>
            Please{' '}
            <Anchor onClick={openBillingModal}>add billing details</Anchor> to
            enable live mode
          </>
        }
      >
        <HSpace space={8} alignCenter>
          <Switch
            switchedOn
            disabled
            size="small"
            color="yellow"
            label="Test mode"
            labelPosition="trailing"
            type="submit"
          />
        </HSpace>
      </TooltipWhite>
    )
  }

  if (!isVerified) {
    return (
      <TooltipWhite
        text={
          <>
            Please{' '}
            <Link {...(activationLink as any)} passHref legacyBehavior>
              <Anchor
                onClick={() =>
                  trackEvent('dashboard_activation_link_clicked', {
                    event_type: 'interaction',
                    verification_flow: currentOrganisation?.verificationFlow,
                  })
                }
              >
                activate your account
              </Anchor>
            </Link>{' '}
            to enable live mode
          </>
        }
      >
        <Switch
          switchedOn
          disabled
          size="small"
          color="yellow"
          label="Test mode"
          labelPosition="trailing"
          type="submit"
        />
      </TooltipWhite>
    )
  }

  if (
    router.pathname.startsWith('/[org]/[mode]/stays') &&
    currentOrganisation?.staysAccessStatus !== 'live'
  ) {
    return (
      <TooltipWhite text="Stays' live mode is not yet enabled for your organisation. Please contact us for more details.">
        <Switch
          switchedOn
          disabled
          size="small"
          color="yellow"
          label="Test mode"
          labelPosition="trailing"
          type="submit"
        />
      </TooltipWhite>
    )
  }

  const isTestMode = !isVerified || !permissions.liveMode

  const toggleModeHandler = (doesCurrentPathHaveModeSwitch: boolean) => {
    trackEvent('dashboard_live_mode_toggle_clicked', {
      live_mode: !permissions.liveMode,
      event_type: 'interaction',
    })
    if (doesCurrentPathHaveModeSwitch) {
      const [_, updatedAsPath] = toggleModeOnCurrentPath(router)
      location.replace(updatedAsPath)
    } else {
      openDialog({
        customRenderer: () => (
          <VSpace space={16} padding={32}>
            <Text typeStyle="heading3" textAlign="center">
              Switch to {isTestMode ? 'live' : 'test'} mode
            </Text>
            <Text color="grey-700" textAlign="center">
              Switching may cause changes to be lost.
            </Text>
          </VSpace>
        ),
        cancelButtonLabel: 'Back',
        onConfirm: () => {
          if (!pathsModeSwitchMap[router.pathname]) {
            // This shouldn't happen
            // If it does, it's probably a developer error where we forgot to add a mapping
            captureException(
              new Error(`Error switching mode on ${router.pathname}`)
            )
            closeDialog()
            return
          }
          const [_, updatedAsPath] = toggleModeOnPath(
            router,
            pathsModeSwitchMap[router.pathname]
          )
          location.replace(updatedAsPath)
        },
        onCancel: () => {
          closeDialog()
        },
      })
    }
  }

  return (
    <Switch
      switchedOn={isTestMode}
      onChange={() => toggleModeHandler(doesCurrentPathHaveModeSwitch)}
      size="small"
      color="yellow"
      label={
        <Text
          color={isTestMode ? 'grey-900' : 'grey-600'}
          typeStyle="paragraph3"
          fontWeight={isTestMode ? 'medium' : 'normal'}
        >
          Test mode
        </Text>
      }
      labelPosition="trailing"
      type="submit"
    />
  )
}
