import * as React from 'react'
import styles from './Avatar.module.css'

interface Sizes {
  avatarSize: string
  fontSize: string
}

type AvatarStyles = {
  height: Sizes['avatarSize']
  width: Sizes['avatarSize']
  backgroundImage?: AvatarProps['imageUrl']
}

export const avatarSizeMap: { [key: string]: Sizes } = {
  huge: { avatarSize: '64px', fontSize: '32px' },
  large: { avatarSize: '48px', fontSize: '24px' },
  default: { avatarSize: '32px', fontSize: '14px' },
  small: { avatarSize: '24px', fontSize: '12px' },
  smaller: { avatarSize: '20px', fontSize: '12px' },
}

export interface AvatarProps {
  name: string
  size?: 'huge' | 'large' | 'default' | 'small' | 'smaller'
  className?: string
  imageUrl?: string
  hasNotification?: boolean
}

export const Avatar: React.FC<AvatarProps> = ({
  imageUrl,
  name,
  size = 'default',
  hasNotification,
}) => {
  const { avatarSize, fontSize } = avatarSizeMap[size]!

  const avatarStyles: AvatarStyles = { height: avatarSize, width: avatarSize }
  if (imageUrl) {
    avatarStyles.backgroundImage = `url(${imageUrl})`
  }

  return (
    <div className={styles['avatar']} style={avatarStyles} data-testid="avatar">
      {!imageUrl && (
        <span className={styles['fallback-text']} style={{ fontSize }}>
          {name.charAt(0).toUpperCase()}
        </span>
      )}
      {hasNotification && <span className={styles['notification-indicator']} />}
    </div>
  )
}
