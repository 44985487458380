import { Tooltip } from '@components/Tooltip'
import styles from './Search.module.css'

interface SearchInputProps {
  isSearchDisabled?: boolean
  children: React.ReactNode
}

const DISABLE_SEARCH_BAR_MESSAGE =
  "Search is unavailable while we perform some upgrades. It'll be back soon."

export const SearchInputWrapper: React.FC<SearchInputProps> = ({
  isSearchDisabled = false,
  children,
}) => {
  if (isSearchDisabled) {
    return (
      <Tooltip
        text={DISABLE_SEARCH_BAR_MESSAGE}
        placement="top"
        className={styles['tooltip__container']}
      >
        {children}
      </Tooltip>
    )
  }
  return <>{children}</>
}
