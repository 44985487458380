import classNames from 'classnames'
import * as React from 'react'
import styles from './GridItem.module.css'

type GridColumn = 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12 | 13

export interface GridItemProps {
  colStart: Exclude<GridColumn, 13>
  colEnd: GridColumn
  className?: string
}

export const GridItem = React.forwardRef<
  HTMLDivElement,
  React.PropsWithChildren<GridItemProps>
>((props, ref?) => (
  <div
    className={classNames(
      styles[`col-start-${props.colStart}`],
      styles[`col-end-${props.colEnd}`],
      props.className
    )}
    ref={ref}
  >
    {props.children}
  </div>
))
