import { motion } from 'framer-motion'
import Link from 'next/link'
import * as React from 'react'
import { Anchor } from '@components/Anchor'
import { HSpace } from '@components/HSpace'
import { Icon } from '@components/Icon'
import { Text } from '@components/Text'
import { TopBanner } from '@components/TopBanner'
import { TravelOpsSessionBanner } from '@components/TravelOpsSessionBanner'
import { trackEvent } from '@lib/tracking'
import { useWorkspace } from '@lib/workspace-context'

/**
 * This is to ensure that we play the entrance animation for the top banner only
 * when the page first loads instead of every page transition.
 */
let hasAnimationPlayed = false

interface DefaultLayoutTopBannerProps {
  isVerified?: boolean
  showTravelOpsBanner?: boolean
}

export const DefaultLayoutTopBanner: React.FC<
  React.PropsWithChildren<DefaultLayoutTopBannerProps>
> = ({ isVerified, showTravelOpsBanner }) => {
  const { stripeConnectVerificationStatus } = useWorkspace()
  const { permissions } = useWorkspace()

  return (
    <>
      {showTravelOpsBanner &&
        permissions?.scope === 'roles/duffel/travel_ops' && (
          <TravelOpsSessionBanner />
        )}
      {stripeConnectVerificationStatus === 'in_review' && (
        <motion.div
          initial={hasAnimationPlayed ? false : { marginTop: '-36px' }}
          animate={{ marginTop: 0 }}
          transition={{ type: 'tween' }}
          onAnimationComplete={() => {
            hasAnimationPlayed = true
          }}
        >
          <TopBanner icon="clock" title="Reviewing your business details">
            <Text fontSize="C2">
              This usually takes less than 2 business days, and we’ll let you
              know if we need any more details.
            </Text>
          </TopBanner>
        </motion.div>
      )}

      {!!isVerified &&
        stripeConnectVerificationStatus === 'requirements_due' && (
          <motion.div
            initial={hasAnimationPlayed ? false : { marginTop: '-36px' }}
            animate={{ marginTop: 0 }}
            transition={{ type: 'tween' }}
            onAnimationComplete={() => {
              hasAnimationPlayed = true
            }}
          >
            <TopBanner
              intent="warning"
              icon="error_outline"
              title="We need a few more details"
            >
              <HSpace space={4} alignCenter>
                <Text fontSize="C2" className="top-banner__text">
                  Please tell us a little more about your business to start
                  selling flights.
                </Text>
                <Text
                  fontWeight="medium"
                  fontSize="C2"
                  className="top-banner__text"
                  asElement="div"
                >
                  <Link
                    href="/stripe-verification-redirect"
                    passHref
                    legacyBehavior
                  >
                    <Anchor
                      onClick={() =>
                        trackEvent('dashboard_stripe_add_details_clicked', {
                          event_type: 'interaction',
                        })
                      }
                    >
                      <HSpace space={4} alignCenter>
                        <span>Add details</span>
                        <Icon name="arrow_right" size={16}></Icon>
                      </HSpace>
                    </Anchor>
                  </Link>
                </Text>
              </HSpace>
            </TopBanner>
          </motion.div>
        )}
    </>
  )
}
