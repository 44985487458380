import * as React from 'react'
import { capitalize } from 'lodash'
import { Stamp, StampProps } from '@components/Stamp'
import { Booking } from '@modules/stays-search/lib/types'

export interface StaysStatusStampProps {
  status: Booking['status']
  isInThePast?: boolean
}

const getStatusColor = (status: Booking['status']): StampProps['color'] => {
  return status === 'cancelled' ? 'pink' : 'green'
}

export const StaysStatusStamp: React.FC<StaysStatusStampProps> = ({
  status,
  isInThePast,
}) => {
  const isPastStatus = isInThePast && status === 'confirmed'
  return (
    <Stamp
      color={isPastStatus ? 'grey' : getStatusColor(status)}
      label={isPastStatus ? 'Past' : capitalize(status.replace('-', ' '))}
      border
      borderRadius="full"
      data-selector="fs-show"
    />
  )
}
