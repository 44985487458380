import { Search } from '@components/Search/Search'
import { useKeyPress } from '@lib/hooks'
import useDebounceValue from '@lib/hooks/use-debounce-value'
import { useDuffelPopper } from '@lib/hooks/use-duffel-popper'
import { captureException } from '@lib/sentry'
import { trackEvent } from '@lib/tracking'
import { JwtPayload } from '@lib/types'
import { useWorkspace } from '@lib/workspace-context'
import { CanceledError } from 'axios'
import classNames from 'classnames'
import * as React from 'react'
import { SearchModal } from '../SearchModal/SearchModal'
import { SearchResponse } from 'pages/api/search'

const SEARCH_QUERY_CHARACTER_THRESHOLD = 2
const FOCUS_ORDER_SEARCH_KEYBOARD_SHORTCUT = '/'
/** How much time (in ms) to wait before showing loading spinner on order search requests */
const SHOW_LOADING_SPINNER_THRESHOLD = 500

export const SearchContainer: React.FC<{ className?: string }> = ({
  className,
}) => {
  const { permissions, user, openSessionExpiredDialog, duffelClient } =
    useWorkspace()
  const { organisation, organisationId, liveMode }: Partial<JwtPayload> =
    permissions || {}
  const { id: userId } = user || {}
  const SearchContainerClassNames = classNames(className)
  const [isPopoverOpen, setIsPopoverOpen] = React.useState<boolean>(false)
  const [searchQuery, setSearchQuery] = React.useState<string>('')
  const debouncedSearchQuery = useDebounceValue<string>(searchQuery, 100)
  const [isLoading, setIsLoading] = React.useState<boolean>(false)
  const [searchError, setSearchError] = React.useState<null | Error>(null)
  // Using a ref to make sure we're not opening the order search modal on page load and only on interaction
  const didMount = React.useRef(false)
  // Using a ref to avoid a re-render when this changes / the event is fired
  const didSearchStartEventFire = React.useRef(false)
  // TODO: move into context to avoid prop drilling
  const [shouldSearchInputBeFocused, setShouldSearchInputBeFocused] =
    React.useState(false)

  const [searchResponse, setSearchResponse] =
    React.useState<SearchResponse | null>(null)

  /** useCallback is necessary to avoid re-registering the keyboard press event handler unnecessarily via the duffel popper */
  const onClosePopover = React.useCallback(
    (event: MouseEvent | KeyboardEvent) => {
      setIsPopoverOpen(false)

      if (event instanceof KeyboardEvent) {
        setSearchQuery('')
      }
    },
    []
  )

  const { popper, setReferenceElement, setPopperElement } = useDuffelPopper(
    isPopoverOpen,
    onClosePopover,
    {
      placement: 'bottom-start',
      modifiers: [{ name: 'offset', options: { offset: [0, 4] } }],
      strategy: 'fixed',
    },
    { shouldInsideClickClose: false }
  )

  useKeyPress(FOCUS_ORDER_SEARCH_KEYBOARD_SHORTCUT, (event) => {
    // If the user is currently typing into an input, disable the search shortcut
    const tag =
      event.target &&
      'tagName' in event.target &&
      (event.target?.tagName as string).toLowerCase()
    if (tag === 'input' || tag === 'textarea') {
      return
    }
    /** allow the FOCUS_ORDER_SEARCH_KEYBOARD_SHORTCUT to be inputted when actively searching  */
    if (shouldSearchInputBeFocused) {
      return
    }
    event.preventDefault() /** prevent FOCUS_ORDER_SEARCH_KEYBOARD_SHORTCUT from being inputted into search when it isn't focused */
    setIsPopoverOpen(true)
  })

  useKeyPress('Tab', (event: KeyboardEvent) => {
    /** ignore if order search modal isn't open, implying the search isn't focused */
    if (!isPopoverOpen) return

    /** initial state */
    if (!searchResponse) {
      setIsPopoverOpen(false)
      return
    }

    /** for all other states except for the empty state, only prevent switching focus */
    event.preventDefault()
    if (searchResponse?.estimatedTotalHits !== 0) return
  })

  React.useEffect(() => {
    const searchOrders = async (
      query: string,
      filter: string[],
      sort: string[],
      abortController: AbortController,
      searchResponseTimeout: NodeJS.Timeout
    ) => {
      const searchResponse = await duffelClient.Manage.search(
        query,
        filter,
        sort,
        abortController
      ).catch((error) => {
        // ignore errors thrown due to cancelled requests
        if (error instanceof CanceledError) {
          return
        } else if (error.message === 'Invalid token') {
          openSessionExpiredDialog()
          return
        }

        setSearchResponse(null)
        setSearchError(error)
        captureException(error)
      })

      setIsLoading(false)
      clearTimeout(searchResponseTimeout)

      if (!searchResponse) {
        return
      }
      setSearchResponse(searchResponse)
      setIsLoading(false)
    }

    // Using a ref to make sure we're not opening the order search modal on mount, but only on interaction
    if (!didMount.current) {
      didMount.current = true
      return
    }

    const abortController = new AbortController()

    // search query must be at least 2 characters (to be able to search owner airline IATA code)
    if (
      !debouncedSearchQuery ||
      debouncedSearchQuery.length < SEARCH_QUERY_CHARACTER_THRESHOLD
    ) {
      setSearchResponse(null)
      return
    }

    if (!didSearchStartEventFire.current) {
      trackEvent('dashboard_order_search_input_search_query_started', {
        event_type: 'interaction',
        mode: liveMode ? 'live' : 'test',
        ...(userId && { userId }),
        ...(organisation && { organisation }),
        ...(organisationId && { organisationId }),
      })
      didSearchStartEventFire.current = true
    }

    const searchResponseTimeout = setTimeout(() => {
      setIsLoading(true)
    }, SHOW_LOADING_SPINNER_THRESHOLD)

    searchOrders(
      debouncedSearchQuery,
      [],
      [],
      abortController,
      searchResponseTimeout
    )

    return () => {
      abortController.abort()
      clearTimeout(searchResponseTimeout)
    }
  }, [debouncedSearchQuery, liveMode, organisation, organisationId, userId])

  /**
   * This `useEffect` is necessary to make sure the search input is correctly focused/blurred whenever the modal is opened/closed.
   * See note below where the `Search` component is used for why the focus/blur state of the input is controlled by
   * `shouldSearchInputBeFocused rather than if the modal is opened or not (`isPopoverOpen`).
   *
   * In addition, without this, if a user presses the FOCUS_ORDER_SEARCH_KEYBOARD_SHORTCUT ("/" key) to open the modal, the input
   * won't be focused.
   */
  React.useEffect(() => {
    setShouldSearchInputBeFocused(isPopoverOpen)
  }, [isPopoverOpen])

  return (
    <div className={SearchContainerClassNames}>
      <Search
        id="order-search-input"
        placeholder="Search"
        value={searchQuery}
        onChange={(value) => {
          setSearchQuery(value)
        }}
        onClear={React.useCallback(() => {
          /** useCallback is necessary so that the Search component doesn't re-register the keyboard press event handler unnecessarily */
          setSearchQuery('')
        }, [])}
        onFocus={() => {
          setIsPopoverOpen(true)
        }}
        padding="medium"
        keyboardShortcut={FOCUS_ORDER_SEARCH_KEYBOARD_SHORTCUT}
        ref={setReferenceElement}
        maskSearchValueOnFullstory
        /**
         * The reason for setting `searchInputIsFocused` to `shouldSearchInputBeFocused` instead of `isPopoverOpen` is that when there are
         * no order search results, the `TAB` key handler will switch the focus to the "View recent orders" link without updating
         * the `isPopoverOpen` state (since changing the value of `isPopoverOpen` would close the modal). Because of this, if the user
         * presses `TAB` again, the focus won't update back to the search input since `isPopoverOpen` won't have had any reason to update
         * and remains `TRUE` whenever `TAB` is pressed.
         */
        searchInputIsFocused={shouldSearchInputBeFocused}
        isLoading={isLoading}
      />
      {isPopoverOpen && (
        <SearchModal
          ref={setPopperElement}
          style={{ ...popper.styles.popper }}
          searchQuery={debouncedSearchQuery}
          searchResponse={searchResponse}
          searchError={searchError}
          {...popper.attributes}
        />
      )}
    </div>
  )
}
