import { UrlObject } from 'url'
import Link from 'next/link'
import { Text } from '@components/legacy-design-system/product/components/Text'

import styles from './SecondaryNavLink.module.css'

export interface SecondaryNavLinkProps {
  href: string | UrlObject
  as?: string
  label: string
  active?: boolean
  onClick?: (event: React.MouseEvent<Element, MouseEvent>) => void
}

export const SecondaryNavLink: React.FC<SecondaryNavLinkProps> = ({
  active,
  as,
  href,
  label,
  onClick,
}) => (
  <Link
    href={href}
    as={as}
    data-testid={`navigation-${label}`}
    onClick={onClick}
    className={styles['link']}
  >
    <Text
      color={active ? 'grey-900' : 'grey-600'}
      typeStyle="paragraph3"
      className={styles['link-text']}
    >
      {label}
    </Text>
  </Link>
)
