import { HSpace } from '@components/HSpace'
import { Text } from '@components/Text'
import { TopBanner } from '@components/TopBanner'
import styles from '../TopBanner/TopBanner.module.css'

export const TravelOpsSessionBanner = () => (
  <TopBanner icon="error_outline" title="TravelOps Session" intent="attention">
    <HSpace space={4} alignCenter>
      <Text fontSize="C2" className={styles['text']}>
        You have temporary access to this organisation's orders.
      </Text>
    </HSpace>
  </TopBanner>
)
