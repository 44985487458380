import classNames from 'classnames'
import { useRouter } from 'next/router'
import * as React from 'react'
import { Avatar } from '@components/Avatar'
import { Icon } from '@components/Icon'
import { getAvatarLink } from '@lib/duffel-client'
import { orgIndexPathArray } from '@lib/paths'
import { DuffelProxy } from '@lib/types'
import { useWorkspace } from '@lib/workspace-context'

interface TeamButtonProps {
  active?: boolean
  onClick?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void
  org: DuffelProxy.Types.SelfOrganisation
}

export const TeamButton: React.FC<TeamButtonProps> = ({ org, active }) => {
  const router = useRouter()
  const { permissions } = useWorkspace()

  if (!permissions) return null

  const handleOnClick = () => {
    router.push(...orgIndexPathArray(org.slug, org.isVerified))
  }

  return (
    <button
      className={classNames('team-button', { 'team-button--active': active })}
      onClick={handleOnClick}
    >
      <span className="team-button__avatar">
        <Avatar size="small" imageUrl={getAvatarLink(org)} name={org.name} />
      </span>
      <span className="team-button__label">{org.name}</span>
      <span className="team-button__icon">
        {active && <Icon size={18} name="check" />}
      </span>

      <style jsx>{`
        .team-button {
          display: flex;
          width: 100%;
          align-items: center;
          box-sizing: border-box;
          -webkit-tap-highlight-color: var(--black);
          -webkit-appearance: none;
          background-color: transparent;
          color: inherit;
          font-family: inherit;
          font-size: inherit;
          font-weight: normal;
          line-height: 1;
          user-select: none;
          border-width: initial;
          border-style: none;
          border-color: initial;
          border-image: initial;
          border-radius: var(--border-radius-4);
          margin: 0px;
          padding: 8px;
          outline: none;
          cursor: pointer;
        }

        .team-button:hover {
          background-color: var(--purple-100);
        }

        .team-button__avatar,
        .team-button__icon {
          flex: 0 0 24px;
        }

        .team-button__label {
          display: block;
          flex-grow: 1;
          text-align: left;
          margin: 0 8px;
          line-height: 24px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }

        .team-button--active .team-button__label {
          font-weight: 600;
        }

        .team-button__icon {
          color: var(--purple-500);
        }
      `}</style>
    </button>
  )
}
