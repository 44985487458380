import { Text } from '@components/legacy-design-system/product/components/Text'
import * as React from 'react'
import { useRouter } from 'next/router'
import { HSpace } from '@components/HSpace'
import { Icon } from '@components/Icon'
import { PopoverContainer } from '@components/PopoverContainer'
import { VSpace } from '@components/VSpace'
import { AuthorisationGuard } from '@lib/security/AuthorisationGuard'
import { useDuffelPopper } from '@lib/hooks'
import {
  airlinesIndexPathObject,
  tokensIndexPathObject,
  linksIndexPathObject,
} from '@lib/paths'
import { useWorkspace } from '@lib/workspace-context'
import { SecondaryNavLink } from './SecondaryNavLink'
import { doesPathNameMatchOneOf } from './lib'

import styles from './DefaultLayoutHeaderMore.module.css'

export const DefaultLayoutHeaderMore: React.FC = () => {
  // show the popover menu when the user clicks on the "More" button
  const [showMoreLinks, setShowMoreLinks] = React.useState(false)
  const { pathname } = useRouter()
  const { permissions, currentOrganisation } = useWorkspace()
  const { organisation, liveMode } = permissions || {}

  const {
    popper: { styles: popperStyles, attributes },
    setReferenceElement,
    setPopperElement,
  } = useDuffelPopper(showMoreLinks, () => setShowMoreLinks(false), {
    placement: 'bottom-start',
  })

  let active: null | 'airlines' | 'developers' | 'links' = null
  if (doesPathNameMatchOneOf(pathname, ['airlinesIndex', 'airlinesShow'])) {
    active = 'airlines'
  } else if (
    doesPathNameMatchOneOf(pathname, [
      'tokensIndex',
      'tokensShow',
      'tokensCreate',
      'webhooksIndex',
    ])
  ) {
    active = 'developers'
  } else if (doesPathNameMatchOneOf(pathname, ['linksIndex'])) {
    active = 'links'
  }

  return (
    <AuthorisationGuard requiredRole="roles/user/developer">
      <button
        ref={setReferenceElement}
        onClick={() => setShowMoreLinks(true)}
        className={styles['more-link']}
      >
        <HSpace space={4} alignCenter>
          <Text color={active ? 'grey-900' : 'grey-600'} typeStyle="paragraph3">
            {active ? active.charAt(0).toUpperCase() + active.slice(1) : 'More'}
          </Text>
          <Icon name="arrow_drop_down" size={16} color="grey-600" />
        </HSpace>
      </button>
      {showMoreLinks && (
        <PopoverContainer
          ref={setPopperElement}
          style={{
            zIndex: 50,
            ...popperStyles.popper,
            marginTop: 'var(--space-8)',
            padding: 'var(--space-16)',
            width: '200px',
          }}
          {...attributes.popper}
          data-selector="fs-show"
        >
          <VSpace space={16}>
            <div>
              <SecondaryNavLink
                label="Airlines"
                {...airlinesIndexPathObject(organisation)}
                active={active === 'airlines'}
                onClick={() => setShowMoreLinks(false)}
              />
            </div>
            <div>
              <SecondaryNavLink
                label="Developers"
                {...tokensIndexPathObject(organisation, liveMode)}
                active={active === 'developers'}
                onClick={() => setShowMoreLinks(false)}
              />
            </div>
            {currentOrganisation?.verificationFlow === 'stripe_connect' && (
              <div>
                <SecondaryNavLink
                  label="Links"
                  {...linksIndexPathObject(organisation)}
                  active={active === 'links'}
                  onClick={() => setShowMoreLinks(false)}
                />
              </div>
            )}
          </VSpace>
        </PopoverContainer>
      )}
    </AuthorisationGuard>
  )
}
