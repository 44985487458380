import classNames from 'classnames'
import * as React from 'react'
import { KeyboardShortcut } from '@components/KeyboardShortcut'
import styles from './SearchModalFooter.module.css'

export type SearchModalFooterProps = {
  /**
   * should the contents look like they are disabled?
   * - - -
   * Default: false
   */
  disabled?: boolean
}

export const SearchModalFooter: React.FC<SearchModalFooterProps> = ({
  disabled = false,
}) => {
  const keyboardActionGroupClassNames = classNames(
    styles['keyboard-action-group'],
    {
      [styles['keyboard-action-group--disabled']]: disabled,
    }
  )
  return (
    <footer className={styles['order-search-modal-footer']}>
      <div className={keyboardActionGroupClassNames}>
        <KeyboardShortcut shortcutKey="TAB" />
        or
        <span className={styles['keyboard-shortcut-group']}>
          <KeyboardShortcut shortcutKey={{ icon: 'arrow_up' }} />
          <KeyboardShortcut shortcutKey={{ icon: 'arrow_down' }} />
        </span>
        to navigate
      </div>
      <div className={keyboardActionGroupClassNames}>
        <KeyboardShortcut shortcutKey={{ icon: 'return' }} />
        to select
      </div>
      <div className={keyboardActionGroupClassNames}>
        <KeyboardShortcut shortcutKey="ESC" />
        to cancel
      </div>
    </footer>
  )
}
