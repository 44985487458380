import classNames from 'classnames'
import * as React from 'react'
import { Anchor } from '@components/Anchor'
import { ComplexIcon } from '@components/ComplexIcon'
import { Icon } from '@components/Icon'
import { Text } from '@components/Text'
import { VSpace } from '@components/VSpace'
import { ordersIndexPathObject, staysIndexPathObject } from '@lib/paths'
import { useWorkspace } from '@lib/workspace-context'
import styles from './SearchModalErrorBody.module.css'
import { HSpace } from '@components/HSpace'

export interface SearchModalErrorBodyProps {
  /** CSS class name */
  className?: string
}

export const SearchModalErrorBody: React.FC<SearchModalErrorBodyProps> = ({
  className,
}) => {
  const { permissions } = useWorkspace()
  const { organisation, liveMode } = permissions || {}

  const ordersPageUrl = ordersIndexPathObject(organisation, liveMode).as
  const bookingsPageUrl = staysIndexPathObject(organisation, liveMode).as

  const errorBodyClassNames = classNames(className, styles['body'])

  return (
    <section className={errorBodyClassNames}>
      <ComplexIcon name="warning" />
      <VSpace space={8} className={styles['help-text-container']}>
        <Text
          className={`${styles['help-item']} ${styles['help-text--primary']}`}
          textAlign="center"
          fontSize="C2"
        >
          We've run into an issue and haven't been <br />
          able to complete your search.
        </Text>
        <Text
          className={`${styles['help-item']} ${styles['help-text--secondary']}`}
          textAlign="center"
          color="grey-600"
          fontSize="C2"
        >
          Please try again later.
        </Text>
        <HSpace space={32}>
          <Anchor
            intent="inline"
            asElement="a"
            href={ordersPageUrl}
            className={`${styles['help-item']} ${styles['help-link']}`}
          >
            View flights <Icon name="arrow_right" size={16} />
          </Anchor>
          <Anchor
            intent="inline"
            asElement="a"
            href={bookingsPageUrl}
            className={`${styles['help-item']} ${styles['help-link']}`}
          >
            View stays <Icon name="arrow_right" size={16} />
          </Anchor>
        </HSpace>
      </VSpace>
    </section>
  )
}
