import { PopoverContainer } from '@components/PopoverContainer'
import * as React from 'react'
import { SearchModalErrorBody } from '../SearchModalErrorBody/SearchModalErrorBody'
import { SearchModalFooter } from '../SearchModalFooter/SearchModalFooter'
import { SearchModalHeader } from '../SearchModalHeader/SearchModalHeader'
import { SearchModalInitialBody } from '../SearchModalInitialBody/SearchModalInitialBody'
import { SearchModalResultsBody } from '../SearchModalResultsBody/SearchModalResultsBody'
import styles from './SearchModal.module.css'
import { SearchResponse } from 'pages/api/search'

export type SearchModalProps = {
  /** styles from React popper */
  style?: React.CSSProperties

  /** User's search query */
  searchQuery: string

  /** Search results from Meilisearch */
  searchResponse: SearchResponse | null

  /** Errors relating to order search */
  searchError?: Error | null
}

export const SearchModal = React.forwardRef<HTMLDivElement, SearchModalProps>(
  ({ style, searchQuery, searchResponse, searchError }, ref) => {
    return (
      <PopoverContainer className={styles['container']} style={style} ref={ref}>
        <SearchModalHeader
          searchResponse={searchResponse}
          searchError={searchError}
        />

        {searchError && <SearchModalErrorBody />}

        {!searchError &&
          (searchResponse ? (
            <SearchModalResultsBody
              searchQuery={searchQuery}
              searchResponse={searchResponse}
            />
          ) : (
            <SearchModalInitialBody />
          ))}

        <SearchModalFooter disabled={!searchResponse?.estimatedTotalHits} />
      </PopoverContainer>
    )
  }
)
