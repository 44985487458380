import classNames from 'classnames'
import * as React from 'react'
import { Icon } from '@components/Icon'
import styles from './KeyboardShortcut.module.css'

export type KeyboardShortcutProps = {
  /** key used for shortcut */
  shortcutKey: string | { icon: 'arrow_up' | 'arrow_down' | 'return' }

  /** custom css class */
  className?: string
}

export const KeyboardShortcut: React.FC<KeyboardShortcutProps> = ({
  shortcutKey,
  className,
}) => {
  const keyboardShortcutClassNames = classNames(
    className,
    styles['keyboard-shortcut']
  )

  return (
    <span className={keyboardShortcutClassNames} aria-hidden="true">
      {typeof shortcutKey === 'object' ? (
        <Icon name={shortcutKey.icon} size={12} />
      ) : (
        shortcutKey
      )}
    </span>
  )
}
