import * as React from 'react'
import { PolymorphicComponentPropsWithRef } from '@components/legacy-design-system/shared/foundations'
import { ComplexIconName, COMPLEX_ICONS } from './complex-icons-map'

type ComplexIconSize = 32 | 48 | 64

export type ComplexIconProps = PolymorphicComponentPropsWithRef<
  'svg',
  {
    /**
     * The name of the icon you'd like to render
     */
    name: ComplexIconName

    /**
     * The size of the icon you'd like to render
     *
     * - - -
     * Default: `48`
     */
    size?: ComplexIconSize
  }
>

export const ComplexIcon: React.ForwardRefExoticComponent<
  Omit<ComplexIconProps, 'ref'> & React.RefAttributes<SVGSVGElement>
> = React.forwardRef<SVGSVGElement, ComplexIconProps>(
  ({ children, size = 48, name, ...props }, ref) => {
    return (
      <svg width={size} height={size} viewBox="0 0 48 48" ref={ref} {...props}>
        <title>{name} complex icon</title>
        {children}
        {COMPLEX_ICONS[name]}
      </svg>
    )
  }
)
