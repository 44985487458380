export const COMPLEX_ICONS = {
  access: (
    <>
      <rect y="9.75" width="48" height="28.4651" fill="#100024" />
      <path
        d="M23.442 9.75H48.0001V38.2151H10.3257L23.442 9.75Z"
        fill="#B696F9"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M24.1979 26.642C23.2529 30.5606 19.7242 33.4718 15.5155 33.4718C10.583 33.4718 6.58447 29.4732 6.58447 24.5408C6.58447 19.6083 10.583 15.6097 15.5155 15.6097C19.6612 15.6097 23.1471 18.4344 24.1537 22.2641H39.3312H39.3319V22.2648L42.4269 25.3598L39.3312 28.4554L37.5873 26.642H37.5856L34.5464 29.6812L31.6237 26.642H31.4568L28.4176 29.6812L25.4949 26.642H24.1979ZM15.5152 27.8682C17.3528 27.8682 18.8424 26.3785 18.8424 24.541C18.8424 22.7034 17.3528 21.2137 15.5152 21.2137C13.6776 21.2137 12.188 22.7034 12.188 24.541C12.188 26.3785 13.6776 27.8682 15.5152 27.8682Z"
        fill="white"
      />
    </>
  ),
  bar_chart: (
    <>
      <path
        d="M1.83783 3.14636C1.83783 2.46008 2.38641 1.90375 3.06311 1.90375H44.9367C45.6134 1.90375 46.162 2.46009 46.162 3.14636V44.9333C46.162 45.6196 45.6134 46.1759 44.9367 46.1759H3.06311C2.3864 46.1759 1.83783 45.6196 1.83783 44.9333V3.14636Z"
        fill="#100024"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.0632 0.0398254H44.9368C46.6286 0.0398254 48 1.43067 48 3.14637V44.9333C48 46.649 46.6286 48.0398 44.9368 48.0398H3.06319C1.37144 48.0398 0 46.649 0 44.9333V3.14637C0 1.43067 1.37144 0.0398254 3.0632 0.0398254ZM3.0632 1.90375C2.38649 1.90375 1.83792 2.46009 1.83792 3.14637V44.9333C1.83792 45.6196 2.38649 46.1759 3.06319 46.1759H44.9368C45.6135 46.1759 46.1621 45.6196 46.1621 44.9333V3.14637C46.1621 2.46009 45.6135 1.90375 44.9368 1.90375H3.0632Z"
        fill="#100024"
      />
      <path
        d="M9.31384 11.859C9.31384 10.8524 10.1185 10.0363 11.1111 10.0363C12.1038 10.0363 12.9085 10.8524 12.9085 11.859V34.3405C12.9085 35.3472 12.1038 36.1633 11.1111 36.1633C10.1185 36.1633 9.31384 35.3472 9.31384 34.3405V11.859Z"
        fill="#FFC350"
      />
      <path
        d="M15.7586 17.9924C15.7586 16.9857 16.5633 16.1696 17.5559 16.1696C18.5485 16.1696 19.3532 16.9857 19.3532 17.9924V34.3405C19.3532 35.3472 18.5485 36.1633 17.5559 36.1633C16.5633 36.1633 15.7586 35.3472 15.7586 34.3405V17.9924Z"
        fill="white"
      />
      <path
        d="M22.2026 8.26607C22.2026 7.2594 23.0073 6.44333 23.9999 6.44333C24.9925 6.44333 25.7972 7.2594 25.7972 8.26607V34.3405C25.7972 35.3472 24.9925 36.1633 23.9999 36.1633C23.0073 36.1633 22.2026 35.3472 22.2026 34.3405V8.26607Z"
        fill="#FFC350"
      />
      <path
        d="M28.6472 26.2469C28.6472 25.2403 29.4519 24.4242 30.4445 24.4242C31.4372 24.4242 32.2418 25.2403 32.2418 26.2469V34.3405C32.2418 35.3472 31.4372 36.1633 30.4445 36.1633C29.4519 36.1633 28.6472 35.3472 28.6472 34.3405V26.2469Z"
        fill="white"
      />
      <path
        d="M35.0914 19.4069C35.0914 18.4002 35.8961 17.5842 36.8887 17.5842C37.8814 17.5842 38.686 18.4002 38.686 19.4069V34.3405C38.686 35.3472 37.8814 36.1633 36.8887 36.1633C35.8961 36.1633 35.0914 35.3472 35.0914 34.3405V19.4069Z"
        fill="#FFC350"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.3016 39.3197L31.4282 39.3189L31.4283 41.1828L17.3017 41.1836L17.3016 39.3197Z"
        fill="white"
      />
    </>
  ),
  card: (
    <>
      <path
        d="M0.223755 9.64575H48.1659V38.0766H0.223755V9.64575Z"
        fill="#100024"
      />
      <path
        d="M23.6369 9.64575H48.1654V38.0766H10.5364L23.6369 9.64575Z"
        fill="#6DA7F2"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.6196 30.272H4.12549V28.5996H18.6196V30.272Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M24.1943 34.1744H4.12549V32.502H24.1943V34.1744Z"
        fill="white"
      />
      <path
        d="M36.4539 20.2182C36.4539 23.6323 33.585 26.4001 30.0459 26.4001C26.5068 26.4001 23.6378 23.6323 23.6378 20.2182C23.6378 16.804 26.5068 14.0363 30.0459 14.0363C33.585 14.0363 36.4539 16.804 36.4539 20.2182Z"
        fill="white"
      />
      <path
        d="M44.7469 19.9921C44.7469 23.5311 41.8779 26.4001 38.3389 26.4001C34.7998 26.4001 31.9308 23.5311 31.9308 19.9921C31.9308 16.453 34.7998 13.584 38.3389 13.584C41.8779 13.584 44.7469 16.453 44.7469 19.9921Z"
        fill="#100024"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M34.16 25.1376C32.6498 23.9646 31.678 22.1313 31.678 20.0709C31.678 18.1423 32.5296 16.4125 33.8772 15.2371C35.3874 16.4101 36.3592 18.2434 36.3592 20.3037C36.3592 22.2324 35.5076 23.9622 34.16 25.1376Z"
        fill="#6DA7F2"
      />
    </>
  ),
  chat: (
    <>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.2188 29.6443C18.2188 21.3386 24.9336 14.6041 33.2152 14.6041C41.4969 14.6041 48.2117 21.3386 48.2117 29.6443V44.6845H33.2152C24.9336 44.6845 18.2188 37.9501 18.2188 29.6443ZM27.334 26.9248H42.0613V25.3326H27.334V26.9248ZM42.0613 32.0659H27.334V30.4737H42.0613V32.0659ZM27.334 37.0196H36.3154V35.4275H27.334V37.0196Z"
        fill="#B696F9"
      />
      <path
        d="M18.2189 3.48462C28.1249 3.48462 36.1569 11.54 36.1569 21.4749C36.1569 31.4098 28.1249 39.4652 18.2189 39.4652H0.280884V21.4749C0.280884 11.54 8.31286 3.48462 18.2189 3.48462Z"
        fill="#100024"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M34.5647 21.4749C34.5647 12.415 27.2412 5.07679 18.2189 5.07679C9.19656 5.07679 1.87305 12.415 1.87305 21.4749V37.873H18.2189C27.2412 37.873 34.5647 30.5349 34.5647 21.4749ZM36.1569 21.4749C36.1569 11.54 28.1249 3.48462 18.2189 3.48462C8.31286 3.48462 0.280884 11.54 0.280884 21.4749V39.4652H18.2189C28.1249 39.4652 36.1569 31.4098 36.1569 21.4749Z"
        fill="#100024"
      />
      <path
        d="M15.9515 10.2446C16.6625 14.3331 19.8411 17.5482 23.9212 18.306L24.0337 18.3269L23.3501 18.5027C19.5578 19.4778 16.6731 22.5605 15.9515 26.4091C14.8495 22.4909 11.7874 19.4288 7.86926 18.3269C11.73 17.5426 14.8077 14.6291 15.8022 10.8171L15.9515 10.2446Z"
        fill="white"
      />
      <path
        d="M22.5086 21.5835C22.9332 24.0247 24.8311 25.9444 27.2672 26.3968L27.3344 26.4093L26.9262 26.5142C24.6619 27.0965 22.9395 28.9371 22.5086 31.235C21.8507 28.8956 20.0223 27.0673 17.6829 26.4093C19.9881 25.941 21.8257 24.2014 22.4195 21.9253L22.5086 21.5835Z"
        fill="white"
      />
    </>
  ),
  code: (
    <>
      <svg
        width="53"
        height="48"
        viewBox="0 0 53 48"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M4.78931 6.67987C4.78931 6.00431 5.33695 5.45667 6.01251 5.45667H47.8151C48.4907 5.45667 49.0383 6.00431 49.0383 6.67987V41.4381C49.0383 42.1136 48.4907 42.6613 47.8151 42.6613H6.01251C5.33695 42.6613 4.78931 42.1136 4.78931 41.4381V6.67987Z"
          fill="#100024"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M6.01284 3.62183H47.8154C49.5043 3.62183 50.8734 4.99094 50.8734 6.67983V41.4381C50.8734 43.1269 49.5043 44.4961 47.8154 44.4961H6.01284C4.32395 44.4961 2.95483 43.1269 2.95483 41.4381V6.67983C2.95483 4.99094 4.32395 3.62183 6.01284 3.62183ZM6.01284 5.45663C5.33728 5.45663 4.78963 6.00427 4.78963 6.67983V41.4381C4.78963 42.1136 5.33728 42.6613 6.01284 42.6613H47.8154C48.491 42.6613 49.0386 42.1136 49.0386 41.4381V6.67983C49.0386 6.00427 48.491 5.45663 47.8154 5.45663H6.01284Z"
          fill="#100024"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M21.2564 36.8517L30.202 21.3575L31.791 22.2749L22.8453 37.7691L21.2564 36.8517Z"
          fill="#FF8963"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M43.9776 29.4775L35.6675 37.7876L34.3701 36.4902L41.3828 29.4775L34.3701 22.4648L35.6675 21.1674L43.9776 29.4775Z"
          fill="#FF8963"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M9.43671 29.4775L17.7468 37.7876L19.0442 36.4902L12.0315 29.4775L19.0442 22.4648L17.7468 21.1674L9.43671 29.4775Z"
          fill="#FF8963"
        />
        <path
          d="M4.78931 6.67987C4.78931 6.00431 5.33695 5.45667 6.01251 5.45667H47.8151C48.4907 5.45667 49.0383 6.00431 49.0383 6.67987V11.5373H4.78931V6.67987Z"
          fill="#FF8963"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M50.8734 13.372H2.95483V6.67983C2.95483 4.99094 4.32395 3.62183 6.01284 3.62183H47.8154C49.5043 3.62183 50.8734 4.99094 50.8734 6.67983V13.372ZM6.01284 5.45663C5.33728 5.45663 4.78963 6.00427 4.78963 6.67983V11.5372H49.0386V6.67983C49.0386 6.00427 48.491 5.45663 47.8154 5.45663H6.01284Z"
          fill="#FF8963"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M52.9733 14.5067H0.853638V12.6719H52.9733V14.5067Z"
          fill="white"
        />
      </svg>
    </>
  ),
  connection: (
    <>
      <path
        d="M4.66443 6.37579C4.66443 5.70091 5.21153 5.15381 5.88641 5.15381H47.6474C48.3223 5.15381 48.8694 5.70091 48.8694 6.37579V41.0994C48.8694 41.7743 48.3223 42.3214 47.6474 42.3214H5.88641C5.21153 42.3214 4.66443 41.7743 4.66443 41.0994V6.37579Z"
        fill="#100024"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.88577 3.3208H47.6468C49.334 3.3208 50.7017 4.68855 50.7017 6.37576V41.0994C50.7017 42.7866 49.334 44.1543 47.6468 44.1543H5.88577C4.19856 44.1543 2.83081 42.7866 2.83081 41.0994V6.37576C2.83081 4.68856 4.19856 3.3208 5.88577 3.3208ZM5.88577 5.15378C5.21089 5.15378 4.66378 5.70088 4.66378 6.37576V41.0994C4.66378 41.7743 5.21088 42.3214 5.88577 42.3214H47.6468C48.3216 42.3214 48.8687 41.7743 48.8687 41.0994V6.37576C48.8687 5.70088 48.3216 5.15378 47.6468 5.15378H5.88577Z"
        fill="#100024"
      />
      <path
        d="M22.8344 37.579C17.6307 37.5866 13.4189 33.3643 13.4257 28.1479C13.4326 22.9315 17.6582 18.6969 22.8618 18.6893L22.8344 37.579Z"
        fill="white"
      />
      <path
        d="M32.5069 18.6948C37.7106 18.6873 41.9224 22.9096 41.9156 28.126C41.9088 33.3424 37.6831 37.577 32.4795 37.5846L32.5069 18.6948Z"
        fill="#FD6F77"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M36.061 25.5572H26.3723V23.7242H36.061V25.5572Z"
        fill="#FD6F77"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M36.061 32.3278H26.3723V30.4949H36.061V32.3278Z"
        fill="#FD6F77"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.6759 29.0534L6.03259 29.0534L6.03259 27.2205L13.6759 27.2205L13.6759 29.0534Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M47.4975 29.0534H39.8694V27.2205H47.4975V29.0534Z"
        fill="#FD6F77"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M52.7995 14.1949H0.731689V12.3619H52.7995V14.1949Z"
        fill="white"
      />
    </>
  ),
  design: (
    <>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.9512 46.7626L31.3581 46.7618L31.3582 48.871L17.9513 48.8718L17.9512 46.7626Z"
        fill="#100024"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M25.5281 38.2036L25.5281 47.3434L23.7838 47.3434L23.7838 38.2036L25.5281 38.2036Z"
        fill="#100024"
      />
      <path
        d="M48.2187 32.5375C48.2187 33.2329 47.6459 33.7966 46.9393 33.7966L3.21595 33.7966C2.50936 33.7966 1.93655 33.2329 1.93655 32.5375L1.93655 3.25767C1.93655 2.56229 2.50936 1.99857 3.21595 1.99857L46.9393 1.99857C47.6459 1.99857 48.2187 2.56229 48.2187 3.25767L48.2187 32.5375Z"
        fill="#100024"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M45.8559 35.9194L3.98226 35.9194C2.2905 35.9194 0.919064 34.531 0.919064 32.8184L0.919066 3.97287C0.919067 2.26021 2.29051 0.871823 3.98226 0.871823L45.8559 0.871827C47.5476 0.871827 48.9191 2.26021 48.9191 3.97287L48.9191 32.8184C48.9191 34.531 47.5476 35.9194 45.8559 35.9194ZM45.8559 34.0588C46.5326 34.0588 47.0812 33.5035 47.0812 32.8184L47.0812 3.97287C47.0812 3.28781 46.5326 2.73245 45.8559 2.73245L3.98226 2.73245C3.30556 2.73245 2.75698 3.28781 2.75698 3.97287L2.75698 32.8184C2.75698 33.5034 3.30556 34.0588 3.98226 34.0588L45.8559 34.0588Z"
        fill="#100024"
      />
      <path
        d="M47.3707 32.9277C47.3707 33.7384 46.815 34.3956 46.1294 34.3956L3.70876 34.3956C3.02321 34.3956 2.46747 33.7384 2.46747 32.9277L2.46747 29.0623L47.3707 29.0623L47.3707 32.9277Z"
        fill="#FD6F77"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.919069 27.5385L48.9191 27.5385L48.9191 32.7607C48.9191 34.5052 47.5476 35.9194 45.8559 35.9194L3.98226 35.9194C2.29051 35.9194 0.919068 34.5052 0.919069 32.7607L0.919069 27.5385ZM45.8559 34.0242C46.5326 34.0242 47.0812 33.4585 47.0812 32.7607L47.0812 29.4337L2.75699 29.4337L2.75699 32.7607C2.75699 33.4585 3.30556 34.0242 3.98226 34.0242L45.8559 34.0242Z"
        fill="#FD6F77"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.919066 27.5385L48.9191 27.5385L48.9191 29.0623L0.919066 29.0623L0.919066 27.5385Z"
        fill="white"
      />
      <path
        d="M40.403 9.25279L46.5965 14.9671L40.403 20.6814L34.2094 14.9671L40.403 9.25279Z"
        fill="#FD6F77"
      />
      <path
        d="M20.274 10.0147H29.5643V19.9195H20.274V10.0147Z"
        fill="#FD6F77"
      />
      <path
        d="M15.6287 14.5861C15.6287 17.5316 13.2024 19.9195 10.2093 19.9195C7.21631 19.9195 4.78998 17.5316 4.78998 14.5861C4.78998 11.6406 7.21631 9.25279 10.2093 9.25279C13.2024 9.25279 15.6287 11.6406 15.6287 14.5861Z"
        fill="#FD6F77"
      />
    </>
  ),
  donut_chart: (
    <>
      <path
        d="M1.83783 3.11624C1.83783 2.42782 2.38641 1.86975 3.06311 1.86975H44.9367C45.6134 1.86975 46.162 2.42783 46.162 3.11625V45.0336C46.162 45.722 45.6134 46.28 44.9367 46.28H3.06311C2.3864 46.28 1.83783 45.722 1.83783 45.0336V3.11624Z"
        fill="#100024"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.06319 0H44.9368C46.6286 0 48 1.39519 48 3.11624V45.0335C48 46.7546 46.6286 48.1498 44.9368 48.1498H3.06319C1.37144 48.1498 0 46.7546 0 45.0335V3.11623C0 1.39518 1.37144 0 3.06319 0ZM3.06319 1.86974C2.38649 1.86974 1.83792 2.42781 1.83792 3.11623V45.0335C1.83792 45.722 2.38649 46.28 3.06319 46.28H44.9368C45.6135 46.28 46.1621 45.722 46.1621 45.0335V3.11624C46.1621 2.42782 45.6135 1.86974 44.9368 1.86974H3.06319Z"
        fill="#100024"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.8404 39.96L30.9669 39.9592L30.967 41.8554L16.8405 41.8562L16.8404 39.96Z"
        fill="white"
      />
      <path
        d="M37.8416 21.3751C37.8416 29.1208 31.6011 35.3999 23.903 35.3999C16.2049 35.3999 9.96442 29.1208 9.96442 21.3751C9.96442 13.6294 16.2049 7.35034 23.903 7.35034C31.6011 7.35034 37.8416 13.6294 37.8416 21.3751ZM13.4491 21.3751C13.4491 27.1844 18.1295 31.8937 23.903 31.8937C29.6766 31.8937 34.357 27.1844 34.357 21.3751C34.357 15.5659 29.6766 10.8565 23.903 10.8565C18.1295 10.8565 13.4491 15.5659 13.4491 21.3751Z"
        fill="white"
      />
      <path
        d="M18.9017 10.1823C18.507 9.29931 18.8967 8.25001 19.8167 7.96613C22.0553 7.27532 24.4406 7.16036 26.7511 7.6455C29.6325 8.25055 32.2497 9.75792 34.2274 11.9516C36.2051 14.1452 37.4418 16.9125 37.7604 19.8569C38.0789 22.8012 37.4628 25.7714 36.0005 28.3419C34.5382 30.9123 32.3047 32.9509 29.6201 34.1656C26.9355 35.3802 23.9376 35.7086 21.0561 35.1035C18.1746 34.4985 15.5575 32.9911 13.5798 30.7975C11.994 29.0385 10.8846 26.9108 10.343 24.6186C10.1204 23.6767 10.8223 22.8072 11.7789 22.7024C12.7355 22.5977 13.5813 23.3 13.8427 24.2318C14.2795 25.7889 15.0698 27.2317 16.1608 28.4417C17.644 30.087 19.6069 31.2175 21.768 31.6713C23.9291 32.125 26.1775 31.8788 28.191 30.9678C30.2044 30.0568 31.8796 28.5278 32.9763 26.6C34.073 24.6722 34.5351 22.4445 34.2962 20.2363C34.0573 18.028 33.1297 15.9525 31.6464 14.3073C30.1632 12.6621 28.2003 11.5315 26.0392 11.0778C24.4498 10.744 22.8131 10.7889 21.2574 11.1985C20.3265 11.4436 19.2963 11.0654 18.9017 10.1823Z"
        fill="#B696F9"
      />
    </>
  ),
  flight: (
    <>
      <path
        d="M8.72872 9.12097C8.72872 8.4114 9.28736 7.83617 9.97647 7.83617H45.4744C46.1635 7.83617 46.7222 8.4114 46.7222 9.12097V45.0819C46.7222 45.7915 46.1635 46.3667 45.4744 46.3667H9.97647C9.28736 46.3667 8.72872 45.7915 8.72872 45.0819V9.12097Z"
        fill="#100024"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.97542 5.90898H45.4734C47.1961 5.90898 48.5927 7.34705 48.5927 9.12098V45.082C48.5927 46.8559 47.1961 48.294 45.4734 48.294H9.97542C8.25264 48.294 6.85606 46.8559 6.85606 45.082V9.12098C6.85606 7.34704 8.25264 5.90898 9.97542 5.90898ZM9.97542 7.83618C9.28631 7.83618 8.72768 8.41141 8.72768 9.12098V45.082C8.72768 45.7915 9.28631 46.3668 9.97542 46.3668H45.4734C46.1625 46.3668 46.7211 45.7915 46.7211 45.082V9.12098C46.7211 8.41141 46.1625 7.83618 45.4734 7.83618H9.97542Z"
        fill="#100024"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M27.7251 43.0159C36.2609 43.0159 43.1806 35.8908 43.1806 27.1015C43.1806 18.3121 36.2609 11.187 27.7251 11.187C19.1892 11.187 12.2696 18.3121 12.2696 27.1015C12.2696 35.8908 19.1892 43.0159 27.7251 43.0159ZM27.7251 44.9431C37.2946 44.9431 45.0522 36.9551 45.0522 27.1015C45.0522 17.2478 37.2946 9.25978 27.7251 9.25978C18.1556 9.25978 10.398 17.2478 10.398 27.1015C10.398 36.9551 18.1556 44.9431 27.7251 44.9431Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M45.0521 28.4614L10.3979 28.4614V26.5342L45.0521 26.5342V28.4614Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M30.8392 38.9846C32.0147 36.0406 32.785 31.8403 32.785 27.1015C32.785 22.3626 32.0147 18.1623 30.8392 15.2183C30.2497 13.7417 29.5951 12.6737 28.9639 12.0051C28.3384 11.3425 27.8556 11.187 27.5333 11.187C27.211 11.187 26.7282 11.3425 26.1027 12.0051C25.4715 12.6737 24.8169 13.7417 24.2274 15.2183C23.0519 18.1623 22.2816 22.3626 22.2816 27.1015C22.2816 31.8403 23.0519 36.0406 24.2274 38.9846C24.8169 40.4612 25.4715 41.5292 26.1027 42.1978C26.7282 42.8604 27.211 43.0159 27.5333 43.0159C27.8556 43.0159 28.3384 42.8604 28.9639 42.1978C29.5951 41.5292 30.2497 40.4612 30.8392 38.9846ZM27.5333 44.9431C31.4674 44.9431 34.6567 36.9551 34.6567 27.1015C34.6567 17.2478 31.4674 9.25978 27.5333 9.25978C23.5992 9.25978 20.4099 17.2478 20.4099 27.1015C20.4099 36.9551 23.5992 44.9431 27.5333 44.9431Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M27.5332 43.0159C33.6595 43.0159 39.3308 36.3417 39.3308 27.1015C39.3308 17.8612 33.6595 11.187 27.5332 11.187C21.4069 11.187 15.7357 17.8612 15.7357 27.1015C15.7357 36.3417 21.4069 43.0159 27.5332 43.0159ZM27.5332 44.9431C35.0825 44.9431 41.2024 36.9551 41.2024 27.1015C41.2024 17.2478 35.0825 9.25978 27.5332 9.25978C19.984 9.25978 13.8641 17.2478 13.8641 27.1015C13.8641 36.9551 19.984 44.9431 27.5332 44.9431Z"
        fill="white"
      />
      <path
        d="M14.1407 23.8494L18.7649 20.6428L24.4847 36.5067L28.0271 34.0502L27.5891 14.5236L32.2769 11.2727C34.2574 9.44448 35.5121 6.92947 35.7992 4.21259C33.2461 3.49772 30.5245 3.7564 28.1357 4.94092L23.5539 8.1182L6.10353 0.293951L2.53992 2.76515L14.7297 14.2374L10.1691 17.4L9.03196 18.6313L6.82646 17.8519L0.592728 15.6278L5.24628 22.743L4.20802 23.8425L3.43816 24.6927L5.24549 27.456L6.28575 27.0193L7.67978 26.4638L12.3902 33.6659L12.7323 26.8818L12.8668 24.3533L14.3033 23.7683L14.1407 23.8494Z"
        fill="#B696F9"
      />
    </>
  ),
  globe: (
    <>
      <path
        d="M46.7092 24.941C46.7092 37.5547 36.4838 47.7802 23.8701 47.7802C11.2563 47.7802 1.03088 37.5547 1.03088 24.941C1.03088 12.3273 11.2563 2.10181 23.8701 2.10181C36.4838 2.10181 46.7092 12.3273 46.7092 24.941Z"
        fill="#100024"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M46.7092 26.2639L1.03088 26.2639L1.03088 24.6331L46.7092 24.6331V26.2639Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M28.7454 40.4706C30.3455 36.5784 31.373 31.0887 31.373 24.941C31.373 18.7932 30.3455 13.3035 28.7454 9.41134C27.9438 7.46136 27.0305 5.99082 26.0998 5.03328C25.174 4.08082 24.3347 3.73263 23.6144 3.73263C22.8942 3.73263 22.0548 4.08082 21.1291 5.03328C20.1983 5.99082 19.2851 7.46136 18.4834 9.41134C16.8833 13.3035 15.8558 18.7932 15.8558 24.941C15.8558 31.0887 16.8833 36.5784 18.4834 40.4706C19.2851 42.4206 20.1983 43.8911 21.1291 44.8487C22.0548 45.8011 22.8942 46.1493 23.6144 46.1493C24.3347 46.1493 25.174 45.8011 26.0998 44.8487C27.0305 43.8911 27.9438 42.4206 28.7454 40.4706ZM23.6144 47.7802C28.8001 47.7802 33.0039 37.5547 33.0039 24.941C33.0039 12.3273 28.8001 2.10181 23.6144 2.10181C18.4288 2.10181 14.225 12.3273 14.225 24.941C14.225 37.5547 18.4288 47.7802 23.6144 47.7802Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M23.615 46.1493C32.3259 46.1493 40.0017 37.0356 40.0017 24.941C40.0017 12.8463 32.3259 3.73263 23.615 3.73263C14.904 3.73263 7.22823 12.8463 7.22823 24.941C7.22823 37.0356 14.904 46.1493 23.615 46.1493ZM23.615 47.7802C33.5658 47.7802 41.6326 37.5547 41.6326 24.941C41.6326 12.3273 33.5658 2.10181 23.615 2.10181C13.6642 2.10181 5.59741 12.3273 5.59741 24.941C5.59741 37.5547 13.6642 47.7802 23.615 47.7802Z"
        fill="white"
      />
      <path
        d="M44.614 10.9845C44.614 16.7834 39.9131 21.4843 34.1142 21.4843C28.3153 21.4843 23.6144 16.7834 23.6144 10.9845C23.6144 5.18561 28.3153 0.48468 34.1142 0.48468C39.9131 0.48468 44.614 5.18561 44.614 10.9845Z"
        fill="#FD6F77"
      />
      <path
        d="M34.1151 28.9362C28.0454 21.5357 24.6317 17.4202 24.077 14.0332L44.1478 14.0332C43.2604 17.7589 39.8277 21.9586 34.1151 28.9362Z"
        fill="#FD6F77"
      />
      <path
        d="M40.4218 10.8306C40.4218 14.3142 37.5978 17.1382 34.1142 17.1382C30.6306 17.1382 27.8066 14.3142 27.8066 10.8306C27.8066 7.34707 30.6306 4.52307 34.1142 4.52307C37.5978 4.52307 40.4218 7.34707 40.4218 10.8306Z"
        fill="white"
      />
    </>
  ),
  mail: (
    <>
      <path
        d="M24.6957 1.47021L47.4911 17.9818H1.90027L24.6957 1.47021Z"
        fill="#100024"
      />
      <path
        d="M23.976 34.4934L1.18057 17.9818L46.7714 17.9818L23.976 34.4934Z"
        fill="#100024"
      />
      <path
        d="M35.7382 22.7306C35.7382 28.8296 30.794 33.7738 24.695 33.7738C18.596 33.7738 13.6517 28.8296 13.6517 22.7306C13.6517 16.6316 18.596 11.6874 24.695 11.6874C30.794 11.6874 35.7382 16.6316 35.7382 22.7306Z"
        fill="#6CBFB0"
      />
      <path
        d="M47.5327 17.8928V51.0847H1.8584V17.8928L24.6956 34.4888L47.5327 17.8928Z"
        fill="#100024"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M24.6955 0.401733L48.4002 17.4482V51.9523H0.990845V17.4482L24.6955 0.401733ZM2.72587 19.5957V49.3819L23.2198 34.4888L2.72587 19.5957ZM24.6955 35.5612L4.5278 50.2172H44.8632L24.6955 35.5612ZM46.6652 49.3819L26.1712 34.4888L46.6652 19.5957V49.3819ZM46.0519 17.8965L24.6955 33.4164L3.33912 17.8965L24.6955 2.5388L46.0519 17.8965Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M31.5708 18.6863L21.8285 28.6214L17.8206 24.5342L19.0594 23.3194L21.8285 26.1433L30.332 17.4716L31.5708 18.6863Z"
        fill="white"
      />
    </>
  ),
  money: (
    <>
      <path
        d="M31.9126 9.75888C31.9126 13.9059 28.6303 17.2678 24.5813 17.2678C20.5323 17.2678 17.25 13.9059 17.25 9.75888C17.25 5.61184 20.5323 2.25 24.5813 2.25C28.6303 2.25 31.9126 5.61184 31.9126 9.75888Z"
        fill="#6CBFB0"
      />
      <path
        d="M47.9355 38.7425V13.5236C41.2716 11.0245 34.0226 10.663 27.1531 12.4871L23.5208 13.4516C16.5512 15.3023 9.25958 15.4872 2.20914 13.9921L0 13.5236V38.7425L0.732619 38.9289C8.7452 40.9679 17.1181 41.0116 25.1505 39.0564L27.4111 38.5062C34.1682 36.8615 41.2163 36.9426 47.9355 38.7425Z"
        fill="#100024"
      />
      <path
        d="M31.5808 25.8588C31.5808 30.3249 28.046 33.9453 23.6855 33.9453C19.3251 33.9453 15.7903 30.3249 15.7903 25.8588C15.7903 21.3928 19.3251 17.7723 23.6855 17.7723C28.046 17.7723 31.5808 21.3928 31.5808 25.8588Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.74776 6.99285C4.41741 6.6545 4.41741 6.10592 4.74776 5.76756L10.1312 0.253766C10.4615 -0.0845885 10.9971 -0.0845885 11.3275 0.253766C11.6578 0.59212 11.6578 1.1407 11.3275 1.47905L7.38816 5.5138H23.3614V7.24662H7.38816L11.3275 11.2814C11.6578 11.6197 11.6578 12.1683 11.3275 12.5066C10.9971 12.845 10.4615 12.845 10.1312 12.5066L4.74776 6.99285Z"
        fill="#100024"
      />
      <path
        d="M46.1626 37.5089C46.1626 41.6559 42.8803 45.0178 38.8313 45.0178C34.7823 45.0178 31.5 41.6559 31.5 37.5089C31.5 33.3618 34.7823 30 38.8313 30C42.8803 30 46.1626 33.3618 46.1626 37.5089Z"
        fill="#6CBFB0"
      />
    </>
  ),
  secure: (
    <>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M31.5352 13.6569L9.48193 13.6569L9.48193 11.9866C9.48193 9.06217 10.6437 6.25749 12.7116 4.18959C14.7795 2.12169 17.5841 0.959961 20.5086 0.959961C23.433 0.959961 26.2377 2.1217 28.3056 4.1896C30.3735 6.25749 31.5352 9.06217 31.5352 11.9866V13.6569ZM11.3025 10.3163C11.6401 8.45553 12.5369 6.72648 13.8927 5.37069C15.6473 3.61603 18.0271 2.63028 20.5086 2.63028C22.99 2.63028 25.3699 3.61603 27.1245 5.37069C28.4803 6.72648 29.377 8.45553 29.7147 10.3163C29.814 10.8637 29.8649 11.4225 29.8649 11.9866L11.1523 11.9866C11.1523 11.4225 11.2032 10.8637 11.3025 10.3163Z"
        fill="#100024"
      />
      <path
        d="M6.22949 11.9867H34.7876V38.8862H6.22949V11.9867Z"
        fill="#100024"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.55933 10.3164H36.4581V40.5565H4.55933V10.3164ZM6.22964 11.9867V38.8862H34.7878V11.9867H6.22964Z"
        fill="#100024"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M22.3227 25.0377C23.4527 24.3755 24.2116 23.1483 24.2116 21.7438C24.2116 19.6368 22.5035 17.9287 20.3965 17.9287C18.2895 17.9287 16.5814 19.6368 16.5814 21.7438C16.5814 23.2246 17.425 24.5084 18.6579 25.1407L15.5535 33.4697H25.4654L22.3227 25.0377Z"
        fill="#FF8963"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M34.7896 48.545V30.9188H36.4599V48.545H34.7896Z"
        fill="#FF8963"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M27.6868 43.4767L42.9622 34.6575L43.7973 36.104L28.522 44.9232L27.6868 43.4767Z"
        fill="#FF8963"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M28.5225 34.6577L43.7979 43.4769L42.9627 44.9235L27.6873 36.1042L28.5225 34.6577Z"
        fill="#FF8963"
      />
    </>
  ),
  tada: (
    <>
      <path
        d="M11.5448 48.033L12.6872 18.8466L39.2903 38.9043L11.5448 48.033Z"
        fill="#100024"
      />
      <path
        d="M27.2685 5.40784C28.2606 11.1129 32.696 15.5992 38.3893 16.6565L38.5463 16.6857L37.5923 16.931C32.3006 18.2917 28.2754 22.5933 27.2685 27.9635C25.7307 22.4962 21.458 18.2234 15.9906 16.6857C21.3779 15.5914 25.6724 11.5259 27.0601 6.20662L27.2685 5.40784Z"
        fill="#FFC350"
      />
      <path
        d="M37.4461 19.7114C37.9301 22.3552 40.0072 24.419 42.654 24.886L42.9475 24.9377L42.2902 25.0983C39.8271 25.7 37.9371 27.6765 37.4461 30.1641C36.6959 27.6302 34.6816 25.669 32.1286 24.9869L31.9447 24.9377C34.574 24.4304 36.6874 22.4745 37.3964 19.8923L37.4461 19.7114Z"
        fill="#100024"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M44.0477 19.1613C44.6554 19.1613 45.148 18.6687 45.148 18.061C45.148 17.4533 44.6554 16.9607 44.0477 16.9607C43.44 16.9607 42.9474 17.4533 42.9474 18.061C42.9474 18.6687 43.44 19.1613 44.0477 19.1613ZM44.0477 20.8117C45.5669 20.8117 46.7984 19.5802 46.7984 18.061C46.7984 16.5418 45.5669 15.3103 44.0477 15.3103C42.5285 15.3103 41.297 16.5418 41.297 18.061C41.297 19.5802 42.5285 20.8117 44.0477 20.8117Z"
        fill="#FFC350"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M37.9156 12.5178V0.456543H39.5661V12.5178H37.9156Z"
        fill="#100024"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M33.1825 8.83012L43.6351 2.79529L44.4603 4.22459L34.0077 10.2594L33.1825 8.83012Z"
        fill="#100024"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M34.0077 2.79536L44.4603 8.8302L43.6351 10.2595L33.1825 4.22467L34.0077 2.79536Z"
        fill="#100024"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.3283 33.8592L7.28987 24.7627L8.33948 23.489L19.3779 32.5856L18.3283 33.8592Z"
        fill="#FFC350"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.3781 41.2693L4.0942 28.6741L5.1438 27.4004L20.4277 39.9956L19.3781 41.2693Z"
        fill="#FFC350"
      />
    </>
  ),
  warning: (
    <>
      <path
        d="M24.6349 3.51331L48.5621 44.9564H0.707764L24.6349 3.51331Z"
        fill="#100024"
      />
      <path
        d="M35.5307 31.4007C35.5307 37.4184 30.6524 42.2967 24.6347 42.2967C18.6171 42.2967 13.7388 37.4184 13.7388 31.4007C13.7388 25.383 18.6171 20.5048 24.6347 20.5048C30.6524 20.5048 35.5307 25.383 35.5307 31.4007Z"
        fill="#6DA7F2"
      />
      <path
        d="M22.518 22.6472L22.7888 28.876L23.5274 34.2185H25.2015L25.9401 28.876L26.2356 22.6472H22.518ZM24.3891 40.0041C25.7432 40.0041 26.4818 39.2901 26.4818 37.9853C26.4818 36.705 25.7186 35.9911 24.3891 35.9911C23.0596 35.9911 22.321 36.705 22.321 37.9853C22.3457 39.2901 23.0843 40.0041 24.3891 40.0041Z"
        fill="white"
      />
    </>
  ),
}

export type ComplexIconName = keyof typeof COMPLEX_ICONS
