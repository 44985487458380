import { Grid } from '@components/Grid/Grid'
import { GridItem } from '@components/Grid/GridItem'
import { HSpace } from '@components/HSpace'
import { ModeToggle } from '@components/ModeToggle'
import { Text } from '@components/legacy-design-system/product/components/Text'
import {
  balanceIndexPathObject,
  doesPathNameMatch,
  ordersIndexPathObject,
  orgInboxPathObject,
  orgIndexPathObject,
  PathKey,
  staysIndexPathObject,
  walletIndexPathObject,
} from '@lib/paths'
import { AuthorisationGuard } from '@lib/security/AuthorisationGuard'
import { useWorkspace } from '@lib/workspace-context'

import classNames from 'classnames'
import { useRouter } from 'next/router'
import * as React from 'react'
import { TestModeNoticeState } from '../DefaultLayout'
import { TeamSwitcher } from '../TeamSwitcher'

import { FeatureFlagsPanel } from '../FeatureFlagsPanel'
import styles from './DefaultLayoutHeader.module.css'
import { DefaultLayoutHeaderMore } from './DefaultLayoutHeaderMore'
import { doesPathNameMatchOneOf } from './lib'
import { SecondaryNavLink } from './SecondaryNavLink'
import { SearchContainer } from '@modules/omni-search/SearchContainer/SearchContainer'
import { isPureDev } from '@lib/env'

export interface DefaultLayoutHeaderProps {
  testModeNotice?: TestModeNoticeState
}

const USER_WITHOUT_ACCESS_TO_MODE_TOGGLE = [
  // eslint-disable-next-line spellcheck/spell-checker
  'usr_0000AaMoFZNzlMAB0fkjUe', // Fouad from delta
  'usr_0000A94eEtSmVCnZZ36aEp', // James test user
]

const FLIGHT_PATHS: PathKey[] = [
  'ordersIndex',
  'ordersShow',
  'flightSearchV2Index',
  'flightSearchV2PartialResult',
  'offerCheckoutV2',
  'ordersChange',
  'ordersChangeResults',
  'ordersChangeOffer',
]

const STAYS_PATH: PathKey[] = [
  'staysIndex',
  'staysSearchIndex',
  'staysSearchResults',
  'staysCheckOut',
  'staysBookingShow',
]

export const DefaultLayoutHeader: React.FC<
  React.PropsWithChildren<DefaultLayoutHeaderProps>
> = ({ testModeNotice = 'current' }) => {
  const { pathname } = useRouter()
  const { permissions, user, currentOrganisation } = useWorkspace()
  const { organisation, liveMode } = permissions || {}
  const currentOrg = user?.organisationsBySlug[permissions?.organisation || '']

  if (!permissions || !user || !organisation || !currentOrg) return null

  let showTestNotice = false
  if (testModeNotice === 'current') {
    showTestNotice = !permissions?.liveMode
  } else if (testModeNotice === 'show') {
    showTestNotice = true
  }

  // Temporary while we test out the inbox - could move to a feature flag
  const showInbox =
    isPureDev ||
    currentOrganisation?.id === 'org_00009UgX9goFl8ES7C2EOe' || // Duffel Staging
    currentOrganisation?.id === 'org_00009UhFcoDDi9M1SF8bKa' // Duffel

  return (
    <>
      <header
        id="header"
        className={classNames(
          styles['container'],
          showTestNotice && styles['container--borderless']
        )}
        data-selector="fs-show"
      >
        <Grid columns={12} alignCenter className={styles['content']}>
          <GridItem colStart={1} colEnd={5}>
            <HSpace space={16} alignCenter>
              <TeamSwitcher />
              {!USER_WITHOUT_ACCESS_TO_MODE_TOGGLE.includes(user.id) && (
                <HSpace space={8} alignCenter>
                  <ModeToggle />
                </HSpace>
              )}
            </HSpace>
          </GridItem>
          <GridItem colStart={5} colEnd={9}>
            <HSpace space={24} asElement="nav" justify="center">
              <AuthorisationGuard requiredRole="roles/user/viewer">
                <AuthorisationGuard requiredRole="roles/user/developer">
                  <SecondaryNavLink
                    {...orgIndexPathObject(organisation, liveMode)}
                    label="Home"
                    active={doesPathNameMatch(pathname, 'orgIndex')}
                  />
                </AuthorisationGuard>
                {showInbox && (
                  <AuthorisationGuard requiredRole="roles/user/developer">
                    <SecondaryNavLink
                      {...orgInboxPathObject(organisation, liveMode)}
                      label="Inbox"
                      active={doesPathNameMatch(pathname, 'inbox')}
                    />
                  </AuthorisationGuard>
                )}

                <SecondaryNavLink
                  label="Flights"
                  {...ordersIndexPathObject(organisation, liveMode)}
                  active={doesPathNameMatchOneOf(pathname, FLIGHT_PATHS)}
                />
              </AuthorisationGuard>
              {currentOrg.staysAccessStatus !== 'disabled' && (
                <AuthorisationGuard requiredRole="roles/user/viewer">
                  <SecondaryNavLink
                    label="Stays"
                    {...staysIndexPathObject(organisation, liveMode)}
                    active={doesPathNameMatchOneOf(pathname, STAYS_PATH)}
                  />
                </AuthorisationGuard>
              )}
              <AuthorisationGuard requiredRole="roles/user/admin">
                {currentOrganisation?.verificationFlow === 'stripe_connect' ? (
                  <SecondaryNavLink
                    label="Balance"
                    {...balanceIndexPathObject(organisation, liveMode)}
                    active={doesPathNameMatch(pathname, 'balanceIndex')}
                  />
                ) : (
                  <SecondaryNavLink
                    label="Balance"
                    {...walletIndexPathObject(organisation, liveMode)}
                    active={doesPathNameMatch(pathname, 'walletIndex')}
                  />
                )}
              </AuthorisationGuard>

              <AuthorisationGuard requiredRole="roles/user/developer">
                <DefaultLayoutHeaderMore />
              </AuthorisationGuard>
            </HSpace>
          </GridItem>
          <GridItem colStart={10} colEnd={13}>
            <HSpace space={16} alignCenter justify="end">
              <FeatureFlagsPanel />
              <SearchContainer className={styles['order-search']} />
            </HSpace>
          </GridItem>
        </Grid>
      </header>
      {showTestNotice && (
        <div className={styles['test-mode-notice']}>
          <Text typeStyle="paragraph4">
            <strong>You are in test mode: </strong>
            No live orders will be created, nor money change hands, while test
            mode is enabled
          </Text>
        </div>
      )}
    </>
  )
}
