import { Anchor } from '@components/Anchor'
import { ComplexIcon } from '@components/ComplexIcon'
import { HSpace } from '@components/HSpace'
import { Icon } from '@components/Icon'
import { Text } from '@components/Text'
import { VSpace } from '@components/VSpace'
import { ordersIndexPathObject, staysIndexPathObject } from '@lib/paths'
import { useWorkspace } from '@lib/workspace-context'
import classNames from 'classnames'
import * as React from 'react'
import styles from './SearchModalEmptyBody.module.css'

export type SearchModalEmptyBodyProps = {
  /** CSS class name */
  className?: string

  /** User's search query */
  searchQuery: string
}

export const SearchModalEmptyBody: React.FC<SearchModalEmptyBodyProps> = ({
  className,
  searchQuery,
}) => {
  const { permissions } = useWorkspace()
  const { organisation, liveMode } = permissions || {}
  const ordersPageUrl = ordersIndexPathObject(organisation, liveMode).as
  const bookingsPageUrl = staysIndexPathObject(organisation, liveMode).as

  const emptyBodyClassNames = classNames(className, styles['body'])
  return (
    <section className={emptyBodyClassNames}>
      <ComplexIcon name="flight" />
      <VSpace space={8} className={styles['help-text-container']}>
        <Text
          className={`${styles['help-item']} ${styles['help-text--primary']}`}
          textAlign="center"
          fontSize="C2"
        >
          No matches for
          <br />"{searchQuery}"
        </Text>
        <Text
          className={`${styles['help-item']} ${styles['help-text--secondary']}`}
          textAlign="center"
          color="grey-600"
          fontSize="C2"
        >
          Looking for a recent order or booking? It can take a few minutes
          <br />
          for them to be indexed by our search engine.
        </Text>
        <HSpace space={32}>
          <Anchor
            intent="inline"
            asElement="a"
            href={ordersPageUrl}
            className={`${styles['help-item']} ${styles['help-link']}`}
          >
            View flights <Icon name="arrow_right" size={16} />
          </Anchor>
          <Anchor
            intent="inline"
            asElement="a"
            href={bookingsPageUrl}
            className={`${styles['help-item']} ${styles['help-link']}`}
          >
            View stays <Icon name="arrow_right" size={16} />
          </Anchor>
        </HSpace>
      </VSpace>
    </section>
  )
}
