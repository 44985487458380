import classNames from 'classnames'
import * as React from 'react'
import { Text } from '@components/Text'
import {
  DEFAULT_ON_FOCUS_DELAY,
  DEFAULT_ON_MOUSE_ENTER_DELAY,
} from '@components/Tooltip'
import { useDuffelPopper } from '@lib/hooks'
import styles from './TooltipWhite.module.css'

export interface TooltipWhiteProps {
  /**
   * Text to be displayed in tooltip.
   */
  text: React.ReactNode

  /**
   * A space-delimited list of class names to pass along to a child element.
   */
  className?: string
}

export const TooltipWhite: React.FC<
  React.PropsWithChildren<TooltipWhiteProps>
> = ({ children, text, className }) => {
  const timeoutId = React.useRef<ReturnType<typeof setTimeout> | null>(null)
  const [isMouseOverTooltip, setIsMouseOverTooltip] = React.useState(false)
  const [isMouseOverRefElement, setIsMouseOverRefElement] =
    React.useState(false)
  const [isVisible, setVisible] = React.useState(false)

  React.useEffect(() => {
    if (!isMouseOverTooltip && !isMouseOverRefElement) {
      onHideTooltip()
    } else {
      onShowTooltip(DEFAULT_ON_MOUSE_ENTER_DELAY)()
    }
  }, [isMouseOverTooltip, isMouseOverRefElement])

  const onShowTooltip = (timeout: number) => () => {
    const id = setTimeout(() => {
      setVisible(true)
    }, timeout)

    timeoutId.current = id
  }

  const onHideTooltip = () => {
    if (timeoutId.current) {
      clearTimeout(timeoutId.current)
    }

    setVisible(false)
    timeoutId.current = null
  }

  const {
    popper: { styles: popperStyles, attributes },
    setReferenceElement,
    setPopperElement,
  } = useDuffelPopper(isVisible, onHideTooltip, {
    placement: 'bottom-start',
    modifiers: [{ name: 'offset', options: { offset: [0, 0] } }],
  })

  return (
    <div>
      <span
        role="button"
        ref={setReferenceElement}
        onBlur={onHideTooltip}
        onFocus={onShowTooltip(DEFAULT_ON_FOCUS_DELAY)}
        // Using the 'pointer-events: none' CSS rule will break the 'hover' functionality of the component.
        // Pointer events have been used instead of mouse events due to a React bug.
        // More info: https://github.com/facebook/react/issues/18753
        onPointerEnter={() => setIsMouseOverRefElement(true)}
        onPointerLeave={() => setIsMouseOverRefElement(false)}
      >
        {children}
      </span>

      {isVisible ? (
        <div
          className={classNames(styles['tooltip'], className)}
          ref={setPopperElement}
          style={popperStyles.popper}
          onPointerEnter={() => setIsMouseOverTooltip(true)}
          onPointerLeave={() => setIsMouseOverTooltip(false)}
          {...attributes.popper}
        >
          <div className={classNames(styles['contents'])}>
            <Text fontSize="C2" color="grey-900" asElement="div">
              {text}
            </Text>
          </div>
        </div>
      ) : null}
    </div>
  )
}
