import classNames from 'classnames'
import * as React from 'react'
import { SearchFacet } from '@components/SearchFacet'
import styles from './SearchModalInitialBody.module.css'

export type SearchModalInitialBodyProps = {
  /** CSS class name */
  className?: string
}

export const SearchModalInitialBody: React.FC<SearchModalInitialBodyProps> = ({
  className,
}) => {
  const bodyClassNames = classNames(styles['example-list'], className)
  return (
    <ul className={bodyClassNames}>
      <li className={styles['example-item']}>
        <SearchFacet searchTerm="ABCDEF">
          search by booking reference or other identifier
        </SearchFacet>
      </li>
      <li className={styles['example-item']}>
        <SearchFacet searchTerm="LHR">
          search by airport or city codes
        </SearchFacet>
      </li>
      <li className={styles['example-item']}>
        <SearchFacet searchTerm="Jane Smith">
          search by passenger or guest name
        </SearchFacet>
      </li>
      <li className={styles['example-item']}>
        <SearchFacet searchTerm="Jane Smith">
          search by departure, arrival, check-in or creation date
        </SearchFacet>
      </li>
      <li className={styles['example-item']}>
        <SearchFacet searchTerm="Jane Smith, LHR, JFK">
          combine multiple search terms
        </SearchFacet>
      </li>
    </ul>
  )
}
