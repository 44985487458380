import * as React from 'react'
import { captureException } from '@lib/sentry'

export function getValueFromLocalStorage<T>(key: string, initialValue: T) {
  if (typeof window === 'undefined') {
    return initialValue
  }
  try {
    const item = window.localStorage.getItem(key)
    return item ? JSON.parse(item) : initialValue
  } catch (error) {
    // If error also return initialValue
    captureException(
      new Error(
        `failed to retrieve the value from local storage for key ${key}. Error: ${error}`
      )
    )
    return initialValue
  }
}

const LOCAL_STORAGE_UPDATED_CUSTOM_EVENT_NAME = 'local-storage-updated'

// useLocalStorage will read the value from the local storage upon initiation
// and try to keep the value in sync. It also ensures that the value gets updated when the
// value of the same key is updated elsewhere in the application.
export function useLocalStorage<T>(
  key: string,
  initialValue: T
): [T, (newValue: T) => void] {
  const [storedValue, setStoredValue] = React.useState<T>(initialValue)

  // only set the value post-mount so that we don't run into hydration mismatch errors
  React.useEffect(() => {
    setStoredValue(getValueFromLocalStorage(key, initialValue))
  }, [])

  // using useCallback here just in case the setter becomes part of a dependency array down the line
  const setValue = React.useCallback(
    (newValue: T) => {
      try {
        localStorage.setItem(key, JSON.stringify(newValue))
        setStoredValue(newValue)

        // notify all the useLocalStorage hook about the update.
        // Note: technically we could instead store the value in the context so we can get the synchronize behavior for free, but
        // it might be slightly overkill for the scale of things we are using this for, so we will do this for now.
        document.dispatchEvent(
          new CustomEvent(LOCAL_STORAGE_UPDATED_CUSTOM_EVENT_NAME, {
            detail: { key },
          })
        )
      } catch (error) {
        captureException(
          new Error(`Error setting localStorage key “${key}”: Error: ${error}`)
        )
      }
    },
    [key]
  )

  React.useEffect(() => {
    const handleLocalStorageUpdate = (event: Event) => {
      // need to use type assertion here since `addEventListener`'s type still doesn't recognize CustomEvent
      const eventKey = (event as CustomEvent).detail.key
      if (eventKey === key) {
        setStoredValue(getValueFromLocalStorage(key, storedValue))
      }
    }

    document.addEventListener(
      LOCAL_STORAGE_UPDATED_CUSTOM_EVENT_NAME,
      handleLocalStorageUpdate
    )
    return () => {
      document.removeEventListener(
        LOCAL_STORAGE_UPDATED_CUSTOM_EVENT_NAME,
        handleLocalStorageUpdate
      )
    }
  }, [key, storedValue])

  return [storedValue, setValue]
}
