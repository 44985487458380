import * as React from 'react'
import classNames from 'classnames'
import { ErrorBoundary } from '@components/ErrorBoundary'
import { useWorkspace } from '@lib/workspace-context'
import { DefaultLayoutHeader } from '../DefaultLayoutHeader/DefaultLayoutHeader'
import styles from './DefaultLayout.module.css'
import { DefaultLayoutTopBanner } from './'

/**
 * The test mode notice will display if this prop is set to 'current' whilst
 * in test mode, or set to 'test' is any mode. The latter option can be used to
 * force the test mode notice to appear on pages that don't have a mode. For
 * example, if viewing a test token.
 */
export type TestModeNoticeState = 'current' | 'show' | 'hide'

export interface DefaultLayoutProps {
  /**
   * There is no real use case for this anymore.
   * It always behaves as if `current` was the value set for this prop.
   * If you are reading this and considering removing it, do it!
   */
  testModeNotice?: TestModeNoticeState

  /**
   * This prop is used to show the Travel Ops banner. It only shows if the user has the
   * travel ops scope. This is only be set on the pages where TOps should have access to.
   * In theory it shouldn't be needed. In pages where TOps does not have access,
   * the banner will never be rendered.
   */
  showTravelOpsBanner?: boolean

  /**
   * Hides overflow. Can be set to `false` to implement nested scroll elements.
   *
   * @default
   * true
   */
  scrollable?: boolean
  padding?: boolean
}

export const DefaultLayout: React.FC<
  React.PropsWithChildren<DefaultLayoutProps>
> = ({
  testModeNotice = 'current',
  showTravelOpsBanner = false,
  scrollable = true,
  padding = true,
  children,
}) => {
  const { permissions, user } = useWorkspace()
  const { organisation } = permissions || {}
  const currentOrg = user?.organisationsBySlug[permissions?.organisation || '']

  if (!permissions || !user || !organisation || !currentOrg) return null

  return (
    <div
      className={classNames(styles['default-layout'], {
        [styles['default-layout--no-scroll']]: !scrollable,
        [styles['default-layout--no-padding']]: !padding,
      })}
    >
      <DefaultLayoutTopBanner
        isVerified={currentOrg.isVerified}
        showTravelOpsBanner={showTravelOpsBanner}
      />
      <DefaultLayoutHeader testModeNotice={testModeNotice} />

      <div id="workspace-main" className={styles['default-layout__main']}>
        <ErrorBoundary>{children}</ErrorBoundary>
      </div>
    </div>
  )
}
