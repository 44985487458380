import classNames from 'classnames'
import * as React from 'react'
import { HSpace } from '@components/HSpace'
import { Icon } from '@components/Icon'
import { Input } from '@components/Input'
import { KeyboardShortcut } from '@components/KeyboardShortcut'
import { Spinner } from '@components/Spinner'
import styles from './Search.module.css'
import { SearchInputWrapper } from './SearchInputWrapper'

export interface SearchProps
  extends Omit<
    React.DetailedHTMLProps<
      React.InputHTMLAttributes<HTMLInputElement>,
      HTMLInputElement
    >,
    'ref' | 'onChange' | 'onSubmit' | 'onFocus'
  > {
  /** value of the search input */
  value?: string

  /** overrides the onClear method from HTMLInputElement */
  onClear?: () => void

  /** overrides the onChange method from HTMLInputElement */
  onChange?: (value: string) => void

  /** overrides the onFocus method from HTMLInputElement since this will be used with a HTMLDivElement */
  onFocus?: () => void

  /** how much padding should the component have? */
  padding?: 'small' | 'medium'

  /** keyboard shortcut used to activate search input */
  keyboardShortcut?: string

  /** Should component hide clear button if the input has any value? */
  hideClearButton?: boolean

  /** Should the search value be hidden in FullStory? */
  maskSearchValueOnFullstory?: boolean

  /** Control to force input to be focused */
  searchInputIsFocused?: boolean

  /** Should the loading spinner be displayed? */
  isLoading?: boolean
}

export const Search = React.forwardRef<HTMLInputElement, SearchProps>(
  (
    {
      id = 'search-input',
      value = '',
      className,
      onClear,
      onChange,
      padding = 'small',
      keyboardShortcut,
      hideClearButton,
      onFocus,
      maskSearchValueOnFullstory = false,
      searchInputIsFocused = false,
      isLoading = false,
      disabled,
      ...props
    },
    ref
  ) => {
    const [isFocused, setIsFocused] = React.useState(searchInputIsFocused)
    const searchInputRef = React.useRef<HTMLInputElement>(null)

    const showKeyboardShortCut =
      keyboardShortcut && !isFocused && value.length === 0
    const showClearButton = !hideClearButton && value.length > 0

    const searchContainerClassNames = classNames(
      styles['search-container'],
      className,
      {
        [styles['search-container--focus']]: isFocused,
        [styles['search-container--padding-sm']]: padding === 'small',
        [styles['search-container--padding-med']]: padding === 'medium',
        [styles['search-container--disabled']]: disabled,
      }
    )

    React.useEffect(() => {
      setIsFocused(searchInputIsFocused)

      if (searchInputRef.current) {
        searchInputIsFocused
          ? searchInputRef.current.focus()
          : searchInputRef.current.blur()
      }
    }, [searchInputIsFocused])

    React.useEffect(() => {
      const onDocumentKeyDownHandler = (event: KeyboardEvent) => {
        if (event.key !== 'Escape' || !isFocused) return

        setIsFocused(false)
        searchInputRef?.current?.blur()
        onClear?.()
      }

      document.body.addEventListener('keydown', onDocumentKeyDownHandler)

      return () => {
        document.body.removeEventListener('keydown', onDocumentKeyDownHandler)
      }
    }, [onClear, isFocused])

    return (
      <HSpace
        id="search"
        space={4}
        alignCenter
        className={searchContainerClassNames}
        onFocus={() => {
          onFocus?.()
          setIsFocused(true)
        }}
        onBlur={() => {
          setIsFocused(false)
        }}
        onClick={(event) => {
          event.stopPropagation()
          if (!isFocused && searchInputRef.current) {
            searchInputRef.current.focus()
          }
        }}
        ref={ref}
      >
        <label htmlFor={id} className={styles['input-label']}>
          <Icon name="search" size={16} className="icon icon--search" />
        </label>
        <SearchInputWrapper isSearchDisabled={disabled}>
          <Input
            {...props}
            autoComplete="off"
            value={value}
            id={id}
            name={id}
            aria-label={id}
            width="100%"
            className={styles['search-container__input']}
            onChange={(event) => {
              onChange && onChange(event.currentTarget.value)
            }}
            ref={searchInputRef}
            maskInputValueOnFullstory={maskSearchValueOnFullstory}
            disabled={disabled}
          />
        </SearchInputWrapper>
        {isLoading && (
          <Spinner color="purple" size="small" className={styles['spinner']} />
        )}
        {showKeyboardShortCut && (
          <KeyboardShortcut
            shortcutKey="/"
            className={styles['keyboard-shortcut']}
          />
        )}
        {showClearButton && (
          <Icon
            name="close"
            size={16}
            className="icon icon--clear"
            onClick={() => {
              onClear?.()

              if (!isFocused && searchInputRef.current) {
                searchInputRef.current.focus()
              }
            }}
          />
        )}
        {/* styled-jsx needed to override <Icon /> styles */}
        <style jsx>{`
          :global(.icon) {
            flex-shrink: 0;
          }

          :global(.icon--search) {
            color: var(--grey-600);
          }

          :global(.icon--clear) {
            color: var(--grey-500);
          }

          :global(.icon--clear):hover {
            color: var(--grey-700);
          }
        `}</style>
      </HSpace>
    )
  }
)
