import { Icon } from '@components/Icon'
import { Text } from '@components/Text'
import { VSpace } from '@components/VSpace'
import { getDateString, getDayDiff } from '@lib/date'
import { useKeyPress } from '@lib/hooks'
import { staysBookingShowPathArray } from '@lib/paths'
import { trackEvent } from '@lib/tracking'
import { JwtPayload } from '@lib/types'
import { useWorkspace } from '@lib/workspace-context'
import classNames from 'classnames'
import Image from 'next/image'
import { useRouter } from 'next/router'
import * as React from 'react'
import styles from './StaysSearchResult.module.css'
import { type BookingSearchResult as BookingSearchResultType } from 'pages/api/search'
import { StaysStatusStamp } from '@modules/stays-search/components/StaysStatusStamp/StaysStatusStamp'

export type StaysSearchResultProps = {
  /** Stays booking item returned from Meilisearch */
  booking: BookingSearchResultType

  /** Should the order item have the active/hover styles? */
  active?: boolean

  /** ID to attach to DOM element */
  id: string

  /** onMouseEnter event used to override active order item set on OrderSearchModalResultsBody */
  onMouseEnter: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void
}

export const StaysSearchResult: React.FC<StaysSearchResultProps> = ({
  booking,
  active = false,
  ...buttonElementProps
}) => {
  const router = useRouter()
  const { permissions, user } = useWorkspace()
  const { organisation, organisationId, liveMode }: Partial<JwtPayload> =
    permissions || {}
  const { id: userId } = user || {}

  const resultsButtonClassNames = classNames(styles['result-item'], {
    [styles['result-item--active']]: active,
  })

  const handleOrderSearchResultSelect = () => {
    trackEvent('dashboard_order_search_result_clicked', {
      event_type: 'interaction',
      mode: liveMode ? 'live' : 'test',
      ...(userId && { userId }),
      ...(organisation && { organisation }),
      ...(organisationId && { organisationId }),
    })

    router.push(
      ...staysBookingShowPathArray(
        permissions?.organisation,
        permissions?.liveMode,
        booking.id
      )
    )
  }

  useKeyPress('Enter', (event) => {
    if (!active) return

    event.preventDefault()
    handleOrderSearchResultSelect()
  })

  return (
    <button
      {...buttonElementProps}
      className={resultsButtonClassNames}
      onClick={handleOrderSearchResultSelect}
    >
      {booking.photoUrl && (
        <Image
          height={32}
          width={32}
          alt="TODO: Add alt text"
          src={booking.photoUrl}
          className={styles['image']}
        />
      )}
      <VSpace space={4}>
        <div className={styles['trip-status']}>
          <Text asElement="h2" fontSize="C1" fontWeight="medium">
            {booking.name}
          </Text>
          <StaysStatusStamp
            status={booking.cancelledAt ? 'cancelled' : 'confirmed'}
            isInThePast={getDayDiff(booking.checkInDate, new Date()) < 0}
          />
        </div>
        <div className={styles['trip-details']}>
          <Text fontSize="C2">{booking.location}</Text>

          <span className={styles['delimiter']}>|</span>

          <Text fontSize="C2" className={styles['checkin-and-checkout']}>
            <time dateTime={booking.checkInDate} suppressHydrationWarning>
              {getDateString(booking.checkInDate, 'short')}
            </time>
            <Icon
              name="arrow_right"
              size={12}
              className={styles['trip-icon']}
            />
            <time dateTime={booking.checkOutDate} suppressHydrationWarning>
              {getDateString(booking.checkOutDate, 'short')}
            </time>
          </Text>
          <span className={styles['delimiter']}>|</span>
          <Text fontSize="C2">{booking.reference}</Text>
        </div>
        <Text
          fontSize="C2"
          className={`${styles['text']} ${styles['trip-passengers']}`}
        >
          {booking.guests}
        </Text>
      </VSpace>
    </button>
  )
}
