import { Text } from '@components/legacy-design-system/product/components/Text'
import { useKeyPress } from '@lib/hooks'
import classNames from 'classnames'
import { SearchResponse } from 'pages/api/search'
import * as React from 'react'
import { scroller } from 'react-scroll'
import { OrderSearchResult } from '../OrderSearchResult/OrderSearchResult'
import { SearchModalEmptyBody } from '../SearchModalEmptyBody/SearchModalEmptyBody'
import styles from './SearchModalResultsBody.module.css'
import { StaysSearchResult } from '../StaysSearchResult/StaysSearchResult'

/**
 * How many search result items should show above the selected result item when the scroller is active?
 *
 * This number also affects when the scroller starts scrolling the results list
 * I.e. - if `nextActiveIndex` > `RESULT_ITEM_SCROLL_OFFSET_AMOUNT`, then the scroller will scroll the list
 */
const RESULT_ITEM_SCROLL_OFFSET_AMOUNT = 1

export type SearchModalResultsBodyProps = {
  /** CSS class name(s) */
  className?: string

  /** User's search query */
  searchQuery: string

  /** Search results from Meilisearch */
  searchResponse: SearchResponse
}

export const SearchModalResultsBody: React.FC<SearchModalResultsBodyProps> = ({
  className,
  searchQuery,
  searchResponse,
}) => {
  const searchResponseHits = searchResponse.estimatedTotalHits

  const resultsBodyClassNames = classNames(className, {
    [styles['results-list']]: searchResponseHits && searchResponseHits > 0,
  })
  const [activeResultIndex, setActiveResultIndex] = React.useState(0)
  const [hasMouseMove, setHasMouseMove] = React.useState(false)

  const resultsListElementId = 'order-search-results-list'
  const resultListPadding = 8
  const resultElementHeight = 110

  const scrollerOptions = {
    containerId: resultsListElementId,
    duration: 200,
    // offset set to a negative number to "under-scroll" and show results above the currently selected result item
    offset: -(
      resultListPadding +
      resultElementHeight * RESULT_ITEM_SCROLL_OFFSET_AMOUNT
    ),
    smooth: true,
  }

  React.useEffect(() => {
    let timeoutId: NodeJS.Timeout
    const mouseMoveHandler = () => {
      clearTimeout(timeoutId)
      setHasMouseMove(true)
      timeoutId = setTimeout(() => setHasMouseMove(false), 50)
    }

    document.addEventListener('mousemove', mouseMoveHandler)
    return () => document.removeEventListener('mousemove', mouseMoveHandler)
  }, [])

  const selectNextResultItem = (indexOffset: number): void => {
    if (!searchResponse.bookings && !searchResponse.orders) {
      return
    }

    /** this calculation is a bit more complicated to make sure we get the right index when wrapping from bottom to top and vice-versa */
    const nextActiveIndex =
      (activeResultIndex + indexOffset + searchResponse.returnedHits) %
      searchResponse.returnedHits

    scroller.scrollTo(
      `search-result-option-${nextActiveIndex}`,
      scrollerOptions
    )
    setActiveResultIndex(nextActiveIndex)
  }

  useKeyPress('ArrowDown', (event: KeyboardEvent) => {
    /** this is `< 2` since we only want to navigate the results if there's more than one result */
    if (searchResponseHits && searchResponseHits < 2) return

    /** prevent cursor moving in search input */
    event.preventDefault()

    selectNextResultItem(1)
  })

  useKeyPress('ArrowUp', (event: KeyboardEvent) => {
    if (searchResponseHits && searchResponseHits < 2) return

    /** prevent cursor moving in search input */
    event.preventDefault()

    selectNextResultItem(-1)
  })

  useKeyPress('Tab', (event: KeyboardEvent) => {
    if (searchResponseHits && searchResponseHits < 2) return

    /** prevent focus from changing */
    event.preventDefault()

    selectNextResultItem(1)
  })

  if (!searchResponse || searchResponse.returnedHits === 0) {
    return (
      <SearchModalEmptyBody
        className={resultsBodyClassNames}
        searchQuery={searchQuery}
      />
    )
  }

  const shouldRenderSectionTitles =
    searchResponse.bookings.length !== 0 && searchResponse.orders.length !== 0

  return (
    <ul id={resultsListElementId} className={resultsBodyClassNames}>
      {shouldRenderSectionTitles && <Text>ORDERS</Text>}
      {searchResponse.orders.map((result, resultIndex: number) => (
        <li
          key={`${result.bookingReference}-${resultIndex}`}
          className={styles['results-item']}
        >
          <OrderSearchResult
            id={`search-result-option-${resultIndex}`}
            order={result}
            active={resultIndex === activeResultIndex}
            onMouseEnter={() => {
              if (!hasMouseMove) {
                return
              }

              setActiveResultIndex(resultIndex)
            }}
          />
        </li>
      ))}

      {shouldRenderSectionTitles && <Text>BOOKINGS</Text>}
      {searchResponse.bookings.map((result, resultIndex: number) => (
        <li
          key={`${result.id}-${resultIndex}`}
          className={styles['results-item']}
        >
          <StaysSearchResult
            id={`search-result-option-${
              resultIndex + searchResponse.orders.length - 1
            }`}
            booking={result}
            active={resultIndex === activeResultIndex}
            onMouseEnter={() => {
              if (!hasMouseMove) {
                return
              }

              setActiveResultIndex(resultIndex)
            }}
          />
        </li>
      ))}
    </ul>
  )
}
