import * as React from 'react'
import { SearchModalProps } from '../SearchModal/SearchModal'
import styles from './SearchModalHeader.module.css'
import { SearchResponse } from 'pages/api/search'

export type SearchModalHeaderProps = {
  /** Search results from Meilisearch */
  searchResponse: SearchResponse | null

  /** Errors relating to order search */
  searchError?: SearchModalProps['searchError']
}

export const SearchModalHeader: React.FC<SearchModalHeaderProps> = ({
  searchResponse,
  searchError,
}) => {
  let content = 'No matches'

  if (searchError) {
    content = 'Search service error'
  } else if (!searchResponse) {
    content = 'Example searches'
  } else if (searchResponse && searchResponse.returnedHits > 0) {
    const suffix = searchResponse.returnedHits === 1 ? 'match' : 'matches'
    content = `Showing ${searchResponse.returnedHits} of ${searchResponse.estimatedTotalHits} ${suffix}`
  }

  return (
    <header className={styles['order-search-modal-header']}>{content}</header>
  )
}
